import LoginHeader from './Header';
import styles from './index.module.scss';
import FormBox from './FormBox';

export default function LoginView() {
  return (
    <div className={styles.login}>
      <LoginHeader />

      <div className={styles.content}>
        <FormBox />

      </div>
    </div>
  );
}
