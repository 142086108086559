import styles from './index.module.scss';
import CreateModel from './CreateModel';
import useCreateAIModel from '@/hooks/useCreateAIModel.ts';

export default function TopView() {
  const { createAIModel } = useCreateAIModel();

  return (
    <div className={styles.top}>
      <div className={styles['top-text']}>
        <div className={styles['title-bar']}>
          <div className={styles.tip} />
          <div className={styles.text}>模特图</div>
        </div>

        <div className={styles['content-text']}>只需要任意真人模特图，即可智能生成不同AI模特在各种场景下的商拍图</div>
      </div>

      <CreateModel onClick={() => createAIModel()} />
    </div>
  );
}
