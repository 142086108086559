import { createGlobalState } from 'react-hooks-global-state';
// import { v4 as uuidv4 } from 'uuid';
import { TaskWithItems } from '@/pages/Imgener/module/Sider/model/recordList.model';
import { createMainTaskAndShape } from '@/api/material.ts';

const initialState = {
  currentTask: null as TaskWithItems | null,
  newTask: null as TaskWithItems | null,
};
const { useGlobalState } = createGlobalState(initialState);

const getRandomTask = (taskRealMainId: string, itemNumber: string): TaskWithItems => {
  return {
    coverUrl: null,
    enterpriseId: '',
    gmtCreated: '',
    gmtModified: '',
    id: 0,
    items: [itemNumber], // 商品款号+4位随机数字
    modelId: 0,
    modelUrl: '',
    readStatus: '0',
    sceneMainId: 0,
    sceneUrl: '',
    status: '',
    stepPath: '/imgener/material',
    taskMainId: taskRealMainId,
    taskStatus: 'in_reation',
    taskUpdateTime: '',
    userId: '',
  };
};

export const useCurrentTaskStore = () => {
  const [currentTask, setCurrentTask] = useGlobalState('currentTask');
  const [newTask, setNewTask] = useGlobalState('newTask');
  const setItems = (items: string[]) => {
    if (currentTask) {
      setCurrentTask({
        ...currentTask,
        items,
      });
    }
  };

  const setRead = () => {
    if (currentTask) {
      setCurrentTask({
        ...currentTask,
        readStatus: '1',
      });
    }
  };

  const createNewTask = (taskRealMainId, itemNumber) => {
    createMainTaskAndShape({
      createShapeTaskId: taskRealMainId,
      shapeTaskList: JSON.stringify([{ id: 0, itemNumber, itemLink: '' }])
    });
    setCurrentTask(getRandomTask(taskRealMainId, itemNumber));
    setNewTask(getRandomTask(taskRealMainId, itemNumber));
  };

  return {
    currentTask,
    setCurrentTask,
    setItems,
    setRead,
    createNewTask,
    newTask,
    setNewTask,
  };
};

// export const useTask = () => {
//   const switchTask;
// };
