import { Layout, message, theme } from 'antd';
import { useMount } from 'ahooks';
import { useNavigate } from 'react-router';
import styles from './index.module.scss';
import ContentView from './content';
import logo from '@/assets/image/logo.svg';
import { useCurrentTaskStore } from '@/hooks/useCurrentTask';
import { useRealCurrentTaskId } from '@/hooks/useGlobalTaskId';
import recordListModel from '../Sider/model/recordList.model';
import { isEmpty } from '@/utils';

const { Header, Content, Sider } = Layout;

type IImgenerLayoutProps = {
  SiderChildren: React.ReactNode;
  ContentChildren: React.ReactNode;
};

export default function ImgenerLayout({
  SiderChildren,
  ContentChildren,
}: IImgenerLayoutProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  const { setCurrentTask, createNewTask } = useCurrentTaskStore();
  const { setRealCurrentTaskId } = useRealCurrentTaskId();
  useMount(async () => {
    const currentUrl = new URL(window.location.href.replace('/#', ''));
    const searchParams = new URLSearchParams(currentUrl.search);
    if (searchParams.has('taskMainId') && searchParams.get('taskMainId')) {
      try {
        const record = await recordListModel.getRecordById(searchParams.get('taskMainId')!);
        if (isEmpty(record)) {
          createNewTask(searchParams.get('taskMainId'), `商品款号${Math.random().toString().slice(-4)}`);
        } else {
          setRealCurrentTaskId(searchParams.get('taskMainId')!);
          setCurrentTask(record);
        }
      } catch (e) {
        message.error((e as Error).message);
        navigate('/imgener/');
      }
    } else if (currentUrl.pathname !== '/imgener/' && currentUrl.pathname !== '/imgener') {
      // navigate('/imgener/');
      // eslint-disable-next-line @typescript-eslint/indent, no-restricted-globals
      // location.href = `${location.origin}/#/imgener/`; // TODO 抽离
    }
  });

  return (
    <Layout style={{ height: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          background: colorBgContainer,
          paddingLeft: '2rem',
        }}
        className={styles.header}
      >
        <img src={logo} alt="" />
      </Header>
      <Layout style={{ padding: '2rem' }} className={styles.layout}>
        <Sider width={262} style={{ background: 'transparent' }}>
          {SiderChildren}
        </Sider>
        <Layout style={{ background: 'transparent' }}>
          <Content style={{ marginLeft: '1.25rem', overflow: 'initial' }}>
            <ContentView>{ContentChildren}</ContentView>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
