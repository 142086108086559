import { createGlobalState } from 'react-hooks-global-state';
import { message } from 'antd';
import { isNil } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useRealCurrentTaskId } from './useGlobalTaskId';
import { useCurrentTaskStore } from './useCurrentTask';
import { getVideoFrame, isEmpty } from '@/utils';
import { apiClient } from '@/utils/request';

export interface OneFilesType {
  /** 真实数据表id 一般不用(勿动) */
  id?: number,
  /** 独一id */
  uuidStr: string,
  /** 是视频 */
  isVideo: boolean,
  /** 上传状态  */
  status: 'loading' | 'done' | 'noUpload',
  /** 是否选择使用  */
  isCheck: boolean,
  /** 上传成功素材结果地址 */
  resultFilesUrl?: string,
  /** 上传任务进度 */
  progress?: number,
  /** oss路径 */
  ossPath?: string,
  /** 视频的封面图 */
  coverPicture?: string,
  /** 高清未压缩图片 */
  noCompressionUrl?: string,
}

const { useGlobalState: useUpTaskListState } = createGlobalState({ upTaskList: [] as Promise<any>[] }); // 存储上传任务
const { useGlobalState: useGetFilesUpRes } = createGlobalState({ filesUpRes: [] as OneFilesType[] }); // 存储上传任务结果与进度

export const useFilesToOss = () => {
  const [upTaskList, setUpTaskList] = useUpTaskListState('upTaskList');
  const [filesUpRes, setFilesUpRes] = useGetFilesUpRes('filesUpRes');
  const { realCurrentTaskId } = useRealCurrentTaskId();
  const { currentTask, setCurrentTask } = useCurrentTaskStore();
  const [search] = useSearchParams();

  // 收集等待上传任务
  const collectUploadTask = async (file) => {
    const taskId = isEmpty(realCurrentTaskId) ? currentTask?.taskMainId : realCurrentTaskId;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append('files', file);
    formData.append('shapeTaskId', taskId!);
    formData.append('uuidStr', file.uid);

    // 视频抽帧 消费视频抽帧结果
    if (file.type.includes('video')) {
      const { url, blob } = await createVideoPreFrame(file);
      if (url) {
        updateUpTaskList(file.uid, { coverPicture: url });
        if (currentTask && isNil(currentTask.coverUrl)) {
          setCurrentTask({ ...currentTask, coverUrl: url });
        }
      }
      formData.append('coverPicture', blob);
    }
    const updateList = await apiClient.post('/shape/uploadImage', {
      data: formData,
      headers: config.headers
    });
    consumeUpTaskList([...upTaskList, updateList]);
  };

  // 创建上传任务
  const createUpTaskList = (file) => {
    const isVideo = file.type.includes('video');
    // 校验文件格式
    const allowedFormats = ['.png', '.jpg', '.jpeg', '.mp4', '.mov'];
    const fileExtension = file.name.toLowerCase().slice(file.name.lastIndexOf('.'));
    if (!allowedFormats.includes(fileExtension)) {
      message.error('暂时只支持JPG/JPEG/PNG格式的图片和MP4/MOV格式的视频');
      return;
    }
    // 校验文件大小
    const isLt10M = file.size / 1024 / 1024 < 10;
    const isLt100M = file.size / 1024 / 1024 < 100;
    if (isVideo && !isLt100M) {
      message.error('暂时只支持100MB以内的MP4/MOV格式的视频');
      return;
    }
    if (!isVideo && !isLt10M) {
      message.error('暂时只支持10MB以内的JPG/JPEG/PNG格式的图片');
      return;
    }
    const createFilesUpRes:OneFilesType = {
      uuidStr: file.uid,
      isVideo,
      status: 'loading',
      progress: isVideo ? 20 : 50,
      isCheck: true
    };
    collectUploadTask(file);
    // 生成初始任务
    setFilesUpRes((pre) => [createFilesUpRes, ...pre]);
  };

  // 消费上传任务
  const consumeUpTaskList = async (taskList) => {
    const res = await Promise.allSettled(taskList);
    setUpTaskList([]);
    res?.forEach((item:any) => {
      const { value } = item;
      if (value.code === 200 && search.get('taskMainId') === realCurrentTaskId) {
        const {
          frontUid, signUrl, originUrl, id, noCompression
        } = value.data;
        updateUpTaskList(frontUid, {
          id,
          progress: 100,
          status: 'done',
          ossPath: originUrl,
          resultFilesUrl: signUrl,
          noCompressionUrl: noCompression
        });
      }
    });
  };

  // 视频抽帧
  const createVideoPreFrame = async (file) => {
    const frame = await getVideoFrame(file);
    return frame;
  };

  // 统一更新上传任务进度与结果
  const updateUpTaskList = (uuidStr, data: Partial<OneFilesType>) => {
    if (search.get('taskMainId') === realCurrentTaskId) {
      setFilesUpRes((pre) => {
        return pre.map((preItem) => {
          if (preItem.uuidStr === uuidStr) {
            return {
              ...preItem,
              ...data
            };
          }
          return preItem;
        });
      });
    }
  };

  return {
    upTaskList,
    filesUpRes,
    setUpTaskList,
    setFilesUpRes,
    createUpTaskList,
    consumeUpTaskList,
    createVideoPreFrame,
    updateUpTaskList,
  };
};
