import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash';
import { LeftOutlined } from '@ant-design/icons';
import { GenerateImgList } from '@/components/GenerateImgList';
import { useGenerateHistoryList } from '@/hooks/useGenerateHistory.ts';
import { isEmpty } from '@/utils';
import ImgPreviewer from '@/components/ImgPreviewer';
import Overlay from '@/components/Overlay';
import { getTaskStatus, updateMainTaskPath } from '@/api/modelInfo.ts';
import { DoMain } from '@/constants';
import { useCurrentTaskStore } from '@/hooks/useCurrentTask';

export function ModelGenerationEffect() {
  const [historyList, setHistoryList] = useState([]);
  const [readStatusNUmber, setReadStatusNUmber] = useState('');
  const { currentTask, setCurrentTask } = useCurrentTaskStore();

  const location = useLocation();
  const params = location.state;
  const [query] = useSearchParams();

  const navigate = useNavigate();

  const [currentItem, setCurrentItem] = useState<any>({});
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const onImgClick = useCallback((item: any) => {
    setCurrentItem(item);
    if (item.resultUrl) { setShowOverlay(true); }
  }, [historyList]);

  const onLeftArrowClick = useCallback(() => {
    const arr = (!isEmpty(historyList) ? [...historyList].reverse() : []).filter((i) => !isNil(i)).map((i: any) => Object.values(i.data)).flat(Infinity);
    const index = arr.findIndex((i: any) => i.resultUrl === currentItem.resultUrl);
    if (index > 0) {
      setCurrentItem(arr[index - 1]);
    }
  }, [historyList, currentItem]);

  const onRightArrowClick = useCallback(
    () => {
      const arr = (!isEmpty(historyList) ? [...historyList].reverse() : []).filter((i) => !isNil(i)).map((i:any) => Object.values(i.data)).flat(Infinity);
      const index = arr.findIndex((i: any) => i.resultUrl === currentItem.resultUrl);
      if (index < arr.length - 1) {
        setCurrentItem(arr[index + 1]);
      }
    },
    [historyList, currentItem]
  );

  const { generateHistoryList } = useGenerateHistoryList(query.get('taskMainId') || '');
  const reversedHistoryList = !isEmpty(historyList) ? [...historyList].reverse() : [];
  useEffect(() => {
    const fetchData = async () => {
      const res = await getTaskStatus({
        taskMainId: params?.taskMainId || query.get('taskMainId'),
      });
      if (currentTask !== null) {
        setCurrentTask({ ...currentTask, readStatus: '1' });
      }
      if (!isEmpty(res)) {
        setReadStatusNUmber(JSON.stringify(res));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setHistoryList(generateHistoryList);
  }, [generateHistoryList, query.get('taskMainId')]);

  const onReEdit = useCallback(() => {
    navigate(`/imgener/editModelInformation?taskMainId=${query.get('taskMainId')}`, {
      state: {
        taskMainId: query.get('taskMainId') || 'fc624b83-268f93673281',
        poseList: currentItem.poseList,
        sceneMainId: currentItem.sceneMainId,
        modelId: currentItem.modelId,
      }
    });
  }, [currentItem]);

  const reStart = () => {
    updateMainTaskPath({
      taskId: query.get('taskMainId'),
      stapPath: '/imgener/material',
    });
    const formPath = 'editModelInformation';
    navigate(`/imgener/material?formPath=${formPath}&taskMainId=${query.get('taskMainId')}`);
  };

  return (
    <div className="model-generation-effect">
      <div className="model-generation-mid-content">
        <div className="model-generation-mid-content-top">
          <div className="mid-content-title">
            {/* <div className="title-left" /> */}
            <LeftOutlined
              onClick={() => navigate(`/imgener/editModelInformation?taskMainId=${query.get('taskMainId')}`)}
            />
            <div className="title-right">模特效果图</div>
          </div>
          <img
            className="starBack"
            src={`${DoMain.BaseWebPic}/starBackBlur.png`}
            alt=""
          />
          <div className="mid-content-restart-btn" onClick={() => reStart()}>
            重新创作
          </div>
        </div>

        {/* <div className="mid-content-select"> */}
        {/*  <Select */}
        {/*    defaultValue="全部生成结果" */}
        {/*    style={{ width: 220, height: 30 }} */}
        {/*    onChange={handleChange} */}
        {/*    options={[ */}
        {/*      { value: '0', label: '全部生成结果' }, */}
        {/*      { value: '1', label: '已标记素材' } */}
        {/*    ]} */}
        {/*  /> */}
        {/* </div> */}
        {
            !isEmpty(historyList) && reversedHistoryList.map((item: any) => {
              const keyIndex = uuidv4(); // 使用uuid生成唯一标识符
              if (!isEmpty(item)) {
                return (
                  <div className="generate-content" key={keyIndex}>
                    <GenerateImgList generateImgList={item.data} onImgClick={(img) => onImgClick(img)} />
                    {
                      readStatusNUmber === item.batchId && Number(readStatusNUmber) > 1 && (
                      <div className="gennerate-history-line">
                        ——以下是历史模特效果图记录——
                      </div>
                      )
                    }
                  </div>
                );
              }
              return <div key={keyIndex} />;
            })
        }
        <div className="bottom-div" />
        {showOverlay && (
        <Overlay
          onClose={() => setShowOverlay(false)} onLeftArrowClick={onLeftArrowClick}
          onRightArrowClick={onRightArrowClick}
        >
          {currentItem && (
          <ImgPreviewer
            leftImageUrl={currentItem?.assetUrl} rightImageUrl={currentItem?.resultUrl}
            effectImageInfo={{
              modelName: currentItem.modelName,
              modelUrl: currentItem.modelUrl,
              sceneName: currentItem.sceneName,
              sceneUrl: currentItem.sceneUrl,
              gmtCreated: currentItem.gmtCreated,
              itemIds: currentTask?.items ?? null
            }}
            onReEdit={onReEdit}
          />
          )}
        </Overlay>
        )}
        <div />
      </div>
    </div>
  );
}
