/* eslint-disable jsx-a11y/control-has-associated-label */
import { ReactNode, MouseEventHandler } from 'react';
import './index.scss';
import { CloseOutlined } from '@ant-design/icons';
import arrowImg from './arrow.svg';

type OverlayProps = {
  children: ReactNode;
  onLeftArrowClick: MouseEventHandler<HTMLButtonElement>;
  onRightArrowClick: MouseEventHandler<HTMLButtonElement>;
  onClose: MouseEventHandler<HTMLButtonElement>;
};

function Overlay({
  children,
  onLeftArrowClick,
  onRightArrowClick,
  onClose,
}: OverlayProps) {
  return (
    <div className="overlay-layer">
      <div className="overlay-left">
        <button
          type="button" className="left-arrow"
          onClick={onLeftArrowClick}
        >
          <img src={arrowImg} alt="" />

        </button>
      </div>
      <div className="overlay-content">
        {children}
      </div>
      <div className="overlay-right">
        <button
          type="button" className="right-arrow"
          onClick={onRightArrowClick}
        >
          <img src={arrowImg} alt="" />
        </button>
        <button
          type="button" className="close-button"
          onClick={onClose}
        >
          <CloseOutlined />

        </button>
      </div>
    </div>
  );
}

export default Overlay;
