const limit = {
  current: 10
};

export default function useLimit() {
  const setLimit = (num: number) => {
    limit.current = num;
  };

  return { limit, setLimit };
}
