import { Button } from 'antd';
import React from 'react';
import './index.scss';

/**
 * @description: aiDrawing风格按钮组件
 * @author MY
 * @data 2023-12-12 10:00:00
 * @param {string} text 按钮文字
 * @param {React.ReactNode} headIcon 按钮头部图标
 * @param {React.CSSProperties} outStyle 按钮外部传递样式
 * @return {*}
 */
interface AiDrawBntProps {
  text?: string;
  bntType?: 'text' | 'primary' | 'dashed' | 'link' | 'default' | undefined ;
  headIcon?: React.ReactNode;
  outStyle?: React.CSSProperties;
  isDisabled?: boolean;
  isUseMouse?: boolean;
  onClick?: () => void;
}
export const AiDrawBnt:React.FC<AiDrawBntProps> = React.memo((
  {
    text = 'aiDraw风格按钮',
    headIcon,
    bntType = 'default',
    outStyle = {},
    isDisabled = false,
    isUseMouse = false,
    onClick
  }
) => {
  const [m, setM] = React.useState(false);
  return (
    <div className="ai-draw-bnt">
      <Button
        onClick={onClick}
        className={isDisabled && bntType === 'primary' ? 'ai-draw-bnt-disabled' : ''}
        onMouseEnter={isUseMouse ? () => {
          setM(true);
        } : undefined}
        onMouseLeave={isUseMouse ? () => {
          setM(false);
        } : undefined}
        style={m ? { ...outStyle, color: '#4A9BFF', border: '1px solid #217EFF' } : { ...outStyle }}
        icon={headIcon}
        type={bntType}
        disabled={isDisabled}
      >
        {text}
      </Button>
    </div>
  );
});

/**
 * @description: aiDrawing风格标签头组件
 * @author MY
 * @data 2023-12-12 14:31:00
 * @param {string} theme 主题
 * @param {string} subTheme 子主题
 * @return {*}
 */
interface AiDrawLableProps {
  theme?: string;
  subTheme?: string;
  headIcon?: React.ReactNode;
}
export const AiDrawLabel:React.FC<AiDrawLableProps> = React.memo((
  {
    theme = '主题',
    subTheme = '',
    headIcon = null,
  }
) => {
  return (
    <div className="ai-draw-lable">
      { !headIcon && <span className="head-icon" />}
      <span className="theme">{theme}</span>
      { subTheme && <span className="sub-theme">{subTheme}</span> }
    </div>
  );
});
