import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import './index.scss';
import { PlusOutlined, CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
  Input, message, Modal, Progress
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from '@/utils';
import { AiDrawBnt, AiDrawLabel } from '@/components/FuncComponent';
import FilesUploading from '@/components/FilesUploading';
import { DoMain } from '@/constants';
import style from './style';
import MaterialShow from './MaterialShow';
import { useCreateItemNumberCenter } from '@/hooks/useCreateItemNumberCenter';
import { OneFilesType, useFilesToOss } from '@/hooks/useFilesToOss';
import customModal from '../Imgener/module/Sider/common/model';

import { useRealCurrentTaskId } from '@/hooks/useGlobalTaskId.ts';
import { getHistoryTaskInfo, updateMainTaskPath } from '@/api/modelInfo.ts';
import { checkImgUse } from '@/api/material';
import { useCurrentTaskStore } from '@/hooks/useCurrentTask';
import Overlay from '@/components/Overlay';
import useFakeProgress from '@/hooks/useFakeProgress';

// 上传素材要求
const MATERIAL_UPLOAD_TIPS: Array<string> = [
  '上传含有关联商品的真人模特图片、视频；',
  // '至少上传三个不同姿势动作的素材，生成模特图更自然；',
  '至少上传一张含有关联商品的真人模特图片、视频',
  '要求人物全身照，展现商品不被头发或饰品大量遮挡，背景简洁；',
  '暂不支持白底图、挂拍、平铺或无肢体人台图'
];
// 上传素材示例图片
const MATERIAL_UPLOAD_PIC = [
  {
    url: `${DoMain.BaseWebPic}/unqualifiedExample0.png`,
    text: '真人全身照'
  },
  {
    url: `${DoMain.BaseWebPic}/moreHair.png`,
    text: '头发遮挡商品'
  },
  {
    url: `${DoMain.BaseWebPic}/unqualifiedExample1.png`,
    text: '平铺/挂拍'
  },
  {
    url: `${DoMain.BaseWebPic}/unqualifiedExample2.png`,
    text: '无肢体人台'
  },
  {
    url: `${DoMain.BaseWebPic}/unqualifiedExample3.png`,
    text: '多人照'
  }
];

interface IProps {

}
/**
 * @.description: 素材管理页面
 * @.author MY
 * @.data 2023-12-21 10:00:00
 * @·param {*}
 * @·return {*}
 */
const MaterialManage: React.FC<IProps> = React.memo(() => {
  const navigate = useNavigate();
  const { currentTask, setItems, setCurrentTask } = useCurrentTaskStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputError, setInputError] = useState<string[]>([]);

  const {
    itemNumberCenter,
    addOneItemNumber,
    deleteOneItemNumberById,
    updateItemNumberCenterList,
    createItemNumberTask,
  } = useCreateItemNumberCenter();
  const { realCurrentTaskId } = useRealCurrentTaskId();

  const [query] = useSearchParams();
  const {
    upTaskList,
    filesUpRes,
    updateUpTaskList
  } = useFilesToOss();
  const progressCheck = useFakeProgress(10);
  const [hasGenerateResult, setHasGenerateResult] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskMainId = query.get('taskMainId') || realCurrentTaskId;
        if (!taskMainId) {
          return;
        }
        const historyList = await getHistoryTaskInfo({
          taskMainId,
        });
        if (!isEmpty(historyList)) {
          setHasGenerateResult(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isEmpty(filesUpRes) && currentTask) {
      if (isEmpty(currentTask.coverUrl)) {
        setCurrentTask({ ...currentTask, coverUrl: filesUpRes[0].resultFilesUrl || '' });
      }
    }
  }, [filesUpRes, upTaskList]);

  const [showOverlay, setShowOverlay] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const handleTogglePlay = () => {
    const video = videoRef.current as any;
    if (video) {
      video.play();
      setIsPlaying(false);
    }
  };
  // 单前选择预览图片id
  const [currentPreFile, setCurrentPreFile] = useState<Partial<OneFilesType>>({});
  // 更新后端商品信息方法
  const updateItemNumber = (id: number, type: 'itemNumber' | 'itemLink', value: string) => {
    const isHas = itemNumberCenter?.some((item) => item.id !== id && !isEmpty(value) && item.itemNumber === value);
    if (type === 'itemNumber' && isHas) {
      message.error('该商品款号已填写过，请勿重复填写');
      updateItemNumberCenterList(id, { [type]: '' });
      return;
    }
    setItems(itemNumberCenter.map((item) => item.itemNumber));
    const uniqueIds = new Set();
    const uniqueList = itemNumberCenter.filter((item) => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        return true;
      }
      return false;
    });
    // 创建或者更新数据
    createItemNumberTask(query.get('taskMainId') || realCurrentTaskId, uniqueList);
  };

  // 更新前端商品信息方法
  const changItemNumber = (id: number, type: 'itemNumber' | 'itemLink', value: string) => {
    updateItemNumberCenterList(id, { [type]: value });
  };

  // 标记素材是否选用
  const changeCheck = (uuidStr) => (checked: boolean) => {
    updateUpTaskList(uuidStr, { isCheck: checked });
  };
  // 预览图片
  const preView = (item: any) => () => {
    setCurrentPreFile(item);
    setShowOverlay(true);
  };
  // 左右箭头点击事件
  const onLeftOrRightArrowClick = (type:string) => {
    const arr = (!isEmpty(filesUpRes) ? [...filesUpRes] : []).filter((i) => !isEmpty(i)).map((i: any) => i);
    const index = arr.findIndex((i: any) => i.uuidStr === currentPreFile.uuidStr);
    if (type === 'left') {
      if (index > 0) {
        setCurrentPreFile(arr[index - 1]);
      }
    } else if (type === 'right') {
      if (index < arr.length - 1) {
        setCurrentPreFile(arr[index + 1]);
      }
    }
  };
  // 缓存是打开下一步按钮
  const isOpenNextStep = useMemo(() => {
    return itemNumberCenter.some((item) => !isEmpty(item.itemNumber));
  }, [itemNumberCenter]);

  const [isCheckLack, setIsCheckLack] = useState(false);
  // 图片检测不合格提示
  const showUnqualifiedModal = () => {
    customModal({
      title: '素材不满足要求',
      content: '抱歉，暂不支持白底图、人台图、多人图、模特展示不完整的图片，请上传包含完整真人模特的素材图',
      okText: '重新上传',
      cancleText: '取消编辑',
    });
    setIsCheckLack(false);
  };

  // 跳转到模特编辑页面
  const goToModelEditor = () => {
    if (filesUpRes.length < 1) {
      message.info('当前素材张数不满足生成要求，请至少上传一张素材');
      setIsCheckLack(true);
      return;
    }
    if (currentTask?.items[0].includes('商品款号') && itemNumberCenter[0].itemNumber.includes('商品款号')) {
      message.error('请删除默认款号，填写自己的商品款号！');
      console.log('temNumberCenterList', itemNumberCenter);
      itemNumberCenter.forEach((item) => {
        if (item.itemNumber.includes('商品款号')) {
          setInputError([item.itemNumber]);
        }
      });
      return;
    }
    setIsModalOpen(true);
    setTimeout(async () => {
      // 筛选出图片
      const checkRes:any = await checkImgUse({
        shapeTaskId: realCurrentTaskId,
        fileList: JSON.stringify(filesUpRes)
      });
      if (checkRes.code !== 200) {
        showUnqualifiedModal();
        setIsModalOpen(false);
        return;
      }
      setIsModalOpen(false);
      updateMainTaskPath({
        taskId: realCurrentTaskId,
        stapPath: '/imgener/editModelInformation',
      });
      navigate(`/imgener/editModelInformation?taskMainId=${realCurrentTaskId}`, {
        state: {
          taskMainId: realCurrentTaskId,
        }
      });
    }, 0);
  };

  /**
   * 跳转结果页
   */
  const goToResultPage = () => {
    updateMainTaskPath({
      taskId: realCurrentTaskId,
      stapPath: '/imgener/modelGenerationEffect',
    });
    navigate(`/imgener/modelGenerationEffect?taskMainId=${realCurrentTaskId}`, {
      state: {
        taskMainId: realCurrentTaskId,
      }
    });
  };

  return (
    <>
      <div className="material-mangage">
        {/* 填写商品信息 */}
        <div className="top">
          <div className="head-box-title">
            <img
              className="starBack"
              src={`${DoMain.BaseWebPic}/starBackBlur.png`}
              alt=""
            />
            <AiDrawLabel
              theme="填写商品信息"
              subTheme="商品信息将关联生成的模特图"
            />
            { hasGenerateResult && (
            <AiDrawBnt
              headIcon={null}
              onClick={goToResultPage}
              bntType="link"
              text="查看生成结果"
            />
            )}
          </div>
          <div className="head-box">
            <div className="Item-style">
              {itemNumberCenter?.map((item, index) => (
                <div key={item.id} className="item">
                  <div className="required-box">
                    <div style={{ marginRight: '8px' }}>
                      <span className="required-icon">*</span>
                      <span>商品款号</span>
                    </div>
                    <Input
                      placeholder={index === 0 ? `请输入${currentTask?.items[0] || '123456'}` : '请输入商品款号'}
                      status={inputError.includes(item.itemNumber) ? 'error' : ''}
                      style={{ height: '32px', flex: 1 }}
                      value={item.itemNumber.includes('商品款号') ? '' : item.itemNumber}
                      onChange={(e) => changItemNumber(item.id, 'itemNumber', e.target.value)}
                      onBlur={(e) => updateItemNumber(item.id, 'itemNumber', e.target.value)}
                    />
                  </div>
                  <div className="required-box">
                    <span>关联商品链接</span>
                    <Input
                      placeholder="请输入商品链接"
                      style={{ height: '32px', flex: 1 }}
                      value={item.itemLink}
                      onChange={(e) => changItemNumber(item.id, 'itemLink', e.target.value)}
                      onBlur={(e) => updateItemNumber(item.id, 'itemLink', e.target.value)}
                    />
                  </div>
                  <CloseCircleFilled
                    style={{
                      color: ' #BFBFBF', width: '40px', height: '40px', ...(index === 0 ? { visibility: 'hidden' } : {})
                    }}
                    onClick={
                         index !== 0
                           ? () => {
                             deleteOneItemNumberById(item.id);
                           } : undefined
                    }
                  />
                </div>
              ))}
              { itemNumberCenter?.length < 5 && (
              <AiDrawBnt
                outStyle={{ padding: '0px' }}
                headIcon={<PlusOutlined />}
                onClick={addOneItemNumber}
                bntType="link"
                text="新增搭配商品"
              />
              )}
            </div>
          </div>
          {/* 上传商品素材 */}
          <div className="main-box">
            <AiDrawLabel
              theme="上传商品素材"
              subTheme="将根据上传的素材生成模特图（支持图片或视频）"
            />
            <div className="main-content">
              <div className="files-dispose">
                {filesUpRes.length ? (
                  <div className="up-and-show">
                    <FilesUploading
                      boxType="small"
                      outStyle={{
                        width: '100%', height: '100px', borderRadius: 'none'
                      }}
                      tips="支持点击、拖拽上传；支持多选文件上传"
                      bntDom={(
                        <AiDrawBnt
                          isUseMouse
                          outStyle={{
                            ...style.upFilebnt, width: '108px', height: '32px', fontSize: '14px'
                          }}
                          headIcon={<PlusOutlined />}
                          text="上传素材"
                        />
                      )}
                    />
                    <div>
                      <div className="show-title">
                        <span>已上传素材</span>
                        <InfoCircleOutlined style={{ color: '#9FA8B9', marginLeft: '8.5px', marginRight: '4.5px' }} />
                        {/* <span style={{ color: '#8C8C8C' }}>至少上传三个不同姿势动作的素材，生成模特图更自然</span> */}
                        <span style={{ color: '#8C8C8C' }}>最好上传三个不同姿势动作的素材，生成模特图更自然</span>
                      </div>
                      <div className="show-pic-video">
                        { filesUpRes.length >= 3 && (
                          <>
                            {filesUpRes.map((item) => (
                              <div key={item.uuidStr}>
                                <MaterialShow
                                  showType={item.status}
                                  showFileUrl={item.isVideo ? item.coverPicture : item?.resultFilesUrl}
                                  isVideo={item.isVideo}
                                  isCheck={item.isCheck}
                                  progress={item.progress}
                                  onChangeCheck={changeCheck(item.uuidStr)}
                                  preView={preView(item)}
                                  id={item.id as number}
                                />
                              </div>
                            ))}
                          </>
                        )}
                        { filesUpRes.length < 3 && (
                          <>
                            {filesUpRes?.map((item) => (
                              <div key={item.uuidStr}>
                                <MaterialShow
                                  showType={item.status}
                                  showFileUrl={item.isVideo ? item.coverPicture : item?.resultFilesUrl}
                                  isVideo={item.isVideo}
                                  isCheck={item.isCheck}
                                  progress={item.progress}
                                  onChangeCheck={changeCheck(item.uuidStr)}
                                  preView={preView(item)}
                                />
                              </div>
                            ))}
                            {
                             Array.from({ length: (3 - filesUpRes.length) })?.map((_, i) => (
                               <div key={Number(i)}>
                                 <MaterialShow
                                   showType="noUpload"
                                   warning={isCheckLack}
                                 />
                               </div>
                             ))
                           }
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <FilesUploading
                    outStyle={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                    bntDom={(
                      <AiDrawBnt
                        isUseMouse
                        outStyle={style.upFilebnt}
                        headIcon={<PlusOutlined />}
                        text="上传图片/视频素材"
                      />
                    )}
                  />
                )}
              </div>
              <div className="right-box">
                <div>上传素材要求</div>
                <ul className="hint-list">
                  {MATERIAL_UPLOAD_TIPS.map((item) => <li key={item}>{item}</li>)}
                </ul>
                <div>
                  { MATERIAL_UPLOAD_PIC.map((item, index) => (
                    <div
                      className="hint-list-pic"
                      style={index === 1 ? { width: '73px' } : {}}
                      key={item.text}
                    >
                      <img
                        style={index === 1 ? { width: '73px', height: '73px' } : {}}
                        src={item.url}
                        className="pic-style"
                        alt=""
                      />
                      <div>{item.text}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <AiDrawBnt
            isDisabled={!isOpenNextStep}
            onClick={goToModelEditor}
            outStyle={{ width: '200px', height: '32px', borderRadius: '4px' }}
            bntType="primary"
            text="下一步：编辑模特信息"
          />
        </div>
        <div style={{ height: '60px' }} />
      </div>
      <Modal
        width={340}
        centered
        footer={null}
        closable={false}
        open={isModalOpen}
        style={{ textAlign: 'center' }}
      >
        <div
          style={{
            height: '90px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div>正在预处理商品素材...</div>
          <Progress
            percent={progressCheck} showInfo={false}
          />
        </div>
      </Modal>
      {showOverlay && !isEmpty(currentPreFile) && (
        <Overlay
          onClose={() => setShowOverlay(false)}
          onLeftArrowClick={() => onLeftOrRightArrowClick('left')}
          onRightArrowClick={() => onLeftOrRightArrowClick('right')}
        >
          <>
            {filesUpRes.map((item) => {
              if (item.uuidStr === currentPreFile.uuidStr) {
                return (
                  <div className="preViewModal">
                    {
                    currentPreFile.isVideo ? (
                      <>
                        <video
                          className="preViewModal-video"
                          src={currentPreFile.resultFilesUrl}
                          controls
                          ref={videoRef}
                          onPlay={() => setIsPlaying(false)}
                          onPause={() => setIsPlaying(true)}
                        />
                        { isPlaying && (
                          <div className="preViewModal-video-mask" onClick={handleTogglePlay}>
                            <img
                              style={{ width: '60px', height: '60px' }}
                              src={`${DoMain.BaseWebPic}/playIcon.png`}
                              alt=""
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <img
                        className="preViewModal-img"
                        src={currentPreFile.noCompressionUrl}
                        alt=""
                      />
                    )
                  }
                  </div>
                );
              }
              return null;
            })}
          </>
        </Overlay>
      )}
    </>
  );
});
export default MaterialManage;
