import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosHeaders,
} from 'axios';
import { removeItem, getItem } from '../storage';

interface ApiData<T> {
  data: T
  code: number
}

// interface ApiResponse<T> {
//   data: ApiData<T>
//   // ...其他属性
// }

const InternalAxiosHeaders = new AxiosHeaders();

export default class ApiClient {
  private axiosInstance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use(
      (requestConfig: AxiosRequestConfig): Promise<InternalAxiosRequestConfig> => this.handleRequest(requestConfig),
      (error: any) => this.handleError(error)
    );

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => this.handleResponse(response),
      (error: any) => this.handleError(error)
    );
  }

  private async handleRequest(
    config: AxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> {
    return {
      ...config,
      headers: {
        ...InternalAxiosHeaders,
        ...getItem('authorization') ? { authorization: getItem('authorization') } : {},
        ...config.headers,
      } as AxiosHeaders & Record<string, any>,
    };
  }

  private handleResponse(
    response: AxiosResponse<ApiData<any>>
  ): AxiosResponse<ApiData<any>> {
    if (['401', '403'].includes(String(response.data.code))) {
      // eslint-disable-next-line no-restricted-globals
      location.href = `${location.origin}/#/login`; // TODO 抽离
      removeItem('authorization');
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      throw new Error(((response.data as any).message) as string);
    }

    if (!String(response.data.code).startsWith('2')) {
      throw new Error(((response.data as any).message) as string);
    }
    return response;
  }

  private handleError(error: any) {
    // 错误处理逻辑
    return Promise.reject(error);
  }

  public async get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiData<T>> {
    const { data } = await this.axiosInstance.get<ApiData<T>>(url, config);
    return data;
  }

  public async post<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiData<T>> {
    const { data } = await this.axiosInstance<ApiData<T>>(url, {
      ...config,
      method: 'post',
    });
    return data;
  }

  public async put<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiData<T>> {
    const { data } = await this.axiosInstance.put<ApiData<T>>(
      url,
      undefined,
      config
    );
    return data;
  }

  public async delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<ApiData<T>> {
    const { data } = await this.axiosInstance.delete<ApiData<T>>(url, config);
    return data;
  }
}
