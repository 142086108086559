import { DownOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useCurrentTaskStore } from '@/hooks/useCurrentTask';
import { RecordItemView } from '../Record';
import { RecordListView } from '../RecordList';
import { TopView } from '../Top';
import { TaskWithItems } from '../model/recordList.model';
import { useSiderStore } from '../store';
import empty from './empty.svg';
import styles from './index.module.scss';
import searchEmpty from './search_empty.svg';
import { useRealCurrentTaskId } from '@/hooks/useGlobalTaskId.ts';
import useLimit from '@/hooks/useLimit.ts';

// const isCurrentTaskMatchKeyword = (currentTask: TaskWithItems | null, keyword: string) => {
//   if (!currentTask) return false;
//   return !!(currentTask.items?.find((item) => item.toLowerCase().includes(keyword.toLowerCase())));
// };

export default function SiderView() {
  const [loading, setLoading] = useState<boolean>(false);
  const { limit } = useLimit();
  const [search] = useSearchParams();
  const [resRecordList, setResRecordList] = useState<TaskWithItems[]>([]); // 最终渲染的列
  const { setRealCurrentTaskId } = useRealCurrentTaskId();

  const {
    loadList,
    deleteRecord,
    total,
    setHasSearched,
    setKeyword,
    hasSearched,
  } = useSiderStore();

  const {
    newTask, setNewTask, currentTask, setCurrentTask
  } = useCurrentTaskStore();
  const navigate = useNavigate();

  // const fetchData = useCallback(async () => {
  //   try {
  //     const list = await loadList(limit.current, search.get('taskMainId') || '');
  //     return list;
  //   } catch {
  //     // message.error('获取任务列表失败');
  //     return [];
  //   }
  // }, [loadList, limit]);

  const getNewDate = async () => {
    const list = await loadList(limit.current);
    if (newTask) {
      // 将最新的newTask过滤并重建
      const currentNewList = list.filter((item) => item.taskMainId !== newTask.taskMainId);
      setResRecordList([newTask, ...currentNewList]);
      return;
    }
    setResRecordList(list);
  };

  useEffect(() => {
    // const fetchRecordList = async () => {
    //   await getNewDate()
    // };
    // fetchRecordList();
    getNewDate();
  }, [currentTask, loadList]);

  // useEffect(() => {
  //   setLoading(true);
  //   fetchData().finally(() => {
  //     setLoading(false);
  //   });
  // }, []);

  // TODO HOOKS化 拆分到Model
  const loadMore = async () => {
    try {
      setLoading(true);
      limit.current += 10;
      await getNewDate();
    } catch {
      // message.error('获取任务列表失败');
    } finally {
      setLoading(false);
    }
  };

  const searchHandler = async (text: string) => {
    setKeyword(text);
    limit.current = 10;
    try {
      setLoading(true);
      await loadList(limit.current, search.get('taskMainId') || '');
      setLoading(false);
      setHasSearched(true);
    } catch {
      // message.error('获取任务列表失败');
    }
  };

  const deleteHandler = async (id: string, isNew: boolean = false) => {
    try {
      setLoading(true);
      const isDeleteSelf = id === currentTask?.taskMainId;
      if (!isNew) {
        await deleteRecord(id);
      }
      message.success('删除成功');
      const list = await loadList(limit.current, currentTask?.taskMainId);
      if (currentTask?.taskMainId === id) {
        setNewTask(null);
        setCurrentTask(null);
      } else {
        setResRecordList([...list]);
      }
      setLoading(false);
      if (isDeleteSelf) { navigate('/imgener/'); }
    } catch {
      // message.error('获取任务列表失败');
    }
  };

  const selectHandler = async (itemRecord: any) => {
    setCurrentTask(itemRecord);
    setRealCurrentTaskId(itemRecord?.taskMainId);
    navigate(`${itemRecord.stepPath}?taskMainId=${itemRecord?.taskMainId}`);
  };

  return (
    <div className={styles.sider}>
      <TopView />
      <RecordListView
        onSearch={searchHandler}
        isEmpty={resRecordList.length === 0}
      >
        <Spin spinning={loading}>
          {resRecordList.length === 0 ? (
            <img
              src={hasSearched ? searchEmpty : empty}
              alt=""
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -150%)',
                pointerEvents: 'none',
              }}
            />
          ) : (
            resRecordList.map((record) => {
              const isCurrent = search.get('taskMainId') === record?.taskMainId;
              return (
                <RecordItemView
                  key={record.taskMainId}
                  id={record.taskMainId}
                  coverUrl={isCurrent ? currentTask?.coverUrl || record.coverUrl : record.coverUrl}
                  taskStatus={
                            isCurrent
                              ? currentTask?.taskStatus ?? 'in_reation'
                              : record.taskStatus
                          }
                  readStatus={
                            isCurrent
                              ? currentTask?.readStatus ?? '0'
                              : record.readStatus
                          }
                  items={isCurrent ? currentTask?.items ?? [] : record.items}
                  isNew={record.taskMainId === newTask?.taskMainId}
                  onDropMenuClick={deleteHandler}
                  onSelect={() => selectHandler(record as any)}
                />
              );
            })
          )}
          { total > resRecordList.length ? (
            <div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                height: 46,
                lineHeight: '46px',
                fontWeight: 400,
                color: '#595959',
                fontSize: 14,
              }}
              onClick={loadMore}
            >
              加载更多
              <DownOutlined style={{ marginLeft: 0, width: 30, transform: 'scale(0.8)' }} />
            </div>
          ) : null}
        </Spin>
      </RecordListView>
    </div>
  );
}
