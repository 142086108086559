import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';
import styles from './index.module.scss';
import { useSiderStore } from '../../store';

type IRecordListViewProps = {
  children: React.ReactNode
  isEmpty: boolean
  onSearch: (text: string) => void
};

export default function RecordListView({ children, isEmpty, onSearch }: IRecordListViewProps) {
  const { hasSearched } = useSiderStore();

  const isClear = useRef(false);

  const searchHandler = (text: string) => {
    onSearch(text);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    isClear.current = false;

    setSearchText((e.target as unknown as { value: string }).value);
    if (e.type === 'click') {
      // onSearch('');
      isClear.current = true;
    }
  };

  const blurHandler = () => {
    if (isClear.current === true && searchText === '') {
      onSearch('');
    }
  };

  const [searchText, setSearchText] = useState<string>('');

  return (
    <div className={styles['record-list']}>
      <div className={styles.top}>
        {(!isEmpty || hasSearched) && (
        <Input
          allowClear
          suffix={<SearchOutlined style={{ color: '#BFBFBF' }} onClick={() => searchHandler(searchText)} />}
          className={styles.ipt}
          placeholder="请输入搜索内容"
          value={searchText}
          onChange={(e) => changeHandler(e)}
          onPressEnter={() => onSearch(searchText)}
          onBlur={blurHandler}
        />
        )}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}
