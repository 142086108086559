import { create } from 'zustand';
import recordListModel, { TaskWithItems } from '../model/recordList.model';

export const useSiderStore = create<{
  total: number;
  keyword: string;
  recordList: TaskWithItems[];
  hasSearched: boolean;
  setKeyword:(q: string) => void;
  setHasSearched: (res: boolean) => void;
  setRecordList: (list: TaskWithItems[]) => void;
  setRecordById: (id: string, record: TaskWithItems) => void;
  loadList: (limit: number, taskMainId?: string) => Promise<TaskWithItems[]>;
  deleteRecord: (id: string) => Promise<void>;
}>((set) => {
      const total = 0;
      const setTotal = (num: number) => set({ total: num });

      const keyword = '';
      const setKeyword = (query: string) => set({ keyword: query });

      const hasSearched = false;
      const setHasSearched = (res: boolean) => set({ hasSearched: res });

      const recordList: TaskWithItems[] = [];
      const setRecordList = (list: TaskWithItems[]) => set({ recordList: list });
      const setRecordById = (id: string, record: TaskWithItems) => {
        const index = recordList.findIndex((item) => item.taskMainId === id);
        if (index !== -1) {
          const copiedRecordList = [...recordList];
          copiedRecordList[index] = { ...record };
          setRecordList(copiedRecordList);
        }
      };

      // 获取参数
      const loadList = async (limit: number) => {
        const { keyword: query } = useSiderStore.getState();
        if (query === '') {
          const res = await recordListModel.getListByPage(limit, 0);
          setRecordList(res.data);
          setTotal(res.total);
          setHasSearched(false);
          return res.data;
        }
        const res = await recordListModel.getListByKeyword(query, limit, 0);
        setRecordList(res.data);
        setTotal(res.total);
        return res.data;
      };

      const deleteRecord = async (id: string) => {
        await recordListModel.deleteRecord(id);
      };

      return {
        total,
        keyword,
        setKeyword,
        // pageConfig,
        // setPageConfig,
        hasSearched,
        setHasSearched,
        recordList,
        setRecordList,
        setRecordById,
        loadList,
        deleteRecord,
      };
    });
