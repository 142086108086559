import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  /** 模特promt */
  selectModelInfo: {},
  mainSceneInfo: {},
  subSceneInfo: [],
  poseInfo: [],
};
const { useGlobalState } = createGlobalState(initialState);

/**
 * @description 模特编辑参数
 */
export const useModelEdit = () => {
  const [selectModelInfo, setSelectModelInfo] = useGlobalState('selectModelInfo');
  const [mainSceneInfo, setMainSceneInfo] = useGlobalState('mainSceneInfo');
  const [subSceneInfo, setSubSceneInfo] = useGlobalState('subSceneInfo');
  const [poseInfo, setPoseInfo] = useGlobalState('poseInfo');
  return {
    selectModelInfo,
    setSelectModelInfo,
    mainSceneInfo,
    setMainSceneInfo,
    subSceneInfo,
    setSubSceneInfo,
    poseInfo,
    setPoseInfo,
  };
};
