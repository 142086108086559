import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

export default function CreateModel({ onClick }: { onClick: () => void }) {
  return (
    <Button
      className={styles.btn} icon={<PlusOutlined />}
      onClick={onClick}
    >
      AI模特图创作
    </Button>
  );
}
