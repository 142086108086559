import { useState, useEffect } from 'react';

const useFakeProgress = (initialProgress) => {
  const [progress, setProgress] = useState(initialProgress);

  useEffect(() => {
    const interval = setInterval(() => {
      // 随机增加进度（20-30）
      const randomIncrement = Math.floor(Math.random() * 11) + 20;
      setProgress((prevProgress) => {
        const newProgress = prevProgress + randomIncrement;
        // 进度不能超过 97%
        return newProgress <= 97 ? newProgress : 97;
      });
    }, 1000);

    // 清除 interval
    return () => {
      clearInterval(interval);
    };
  }, []);

  return progress;
};

export default useFakeProgress;
