/**
 * 去掉前后 空格/空行/tab 的正则 预先定义 避免在函数中重复构造
 * @type {RegExp}
 */
const trimReg = /(^\s*)|(\s*$)/g;
/**
 * 判断一个东西是不是空 空格 空字符串 undefined 长度为0的数组及对象会被认为是空的
 * @param key
 * @returns {boolean}
 */
export const isEmpty = (key: any): boolean => {
  if (key == null || key === '') {
    return true;
  }
  if (typeof key === 'string') {
    const trimmedKey = key.replace(trimReg, '');
    if (trimmedKey.length === 0) {
      return true;
    }
    return false;
  } if (typeof key === 'undefined') {
    return true;
  } if (typeof key === 'object') {
    return Object.keys(key).length === 0;
  } if (typeof key === 'boolean') {
    return false;
  } if (typeof key === 'number') {
    return Number.isNaN(key);
  }
  return false;
};

/**
 * @description: canvas视频画图
 * @author MY
 * @date 2023/12/21 14:51
 * @param {HTMLVideoElement} video 视频地址
 * @return {Promise<{blob: Blob, url: string}>} 返回抽帧后的图片地址数组
*/
export const drawVideo = (video: HTMLVideoElement):Promise<{ blob: Blob, url: string }> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        resolve({
          blob: blob!,
          url: URL.createObjectURL(blob!),
        });
      });
    }
  });
};

/**
 * @description: 前端视频抽帧
 * @author MY
 * @date 2023/12/21 14:51
 * @param {File} videoFile 视频地址
 * @param {number} frameTime 关键帧时间 默认1s
 * @return {Promise<{blob: Blob, url: string}>} 返回抽帧后的图片地址数组
*/
export const getVideoFrame = (videoFile:File, frameTime: number = 1):Promise<{ blob: Blob, url: string }> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.currentTime = frameTime;
    video.muted = true;
    video.autoplay = true;
    video.src = URL.createObjectURL(videoFile);
    video.oncanplay = async () => {
      const frame = await drawVideo(video);
      resolve(frame);
    };
  });
};

/**
 * @description 阿里云图片缩放
 * @author MY
 * @date 2023-12-27 12:08:11
 */
export function resizeOSSImage(url: any, width: number, height: number) {
  // 判断是否以 "http" 开头, 不是oss私有文件
  if (typeof url === 'string' && url.startsWith('http')) {
    const urlObject = new URL(url);
    const hasSignature = urlObject.searchParams.has('Signature');
    if (hasSignature) return url;
    // 阿里云 OSS 图片处理参数，这里示例为缩放
    const processingParams = `resize,m_fill,w_${width},h_${height}`;
    // 拼接阿里云 OSS 处理后的链接
    const processedURL = `${url}?x-oss-process=image/${processingParams}`;

    return processedURL;
  }
  // 非 http 链接，不添加后缀，直接返回原链接
  return url;
}
