import { Modal } from 'antd';
import React from 'react';

interface ICustomModalProps {
  content: React.ReactNode; // 模态框的内容
  style?: React.CSSProperties; // 自定义样式
  title?: string; // 标题
  onOk?: () => void; // 确定按钮的回调
  onCancel?: () => void; // 取消按钮的回调
  okText?: string,
  cancleText?: string,
}

// 使用Modal.method()方法封装一个函数式调用的模态框
function customModal({
  content,
  style,
  title,
  onOk,
  onCancel,
  okText,
  cancleText,
}: ICustomModalProps) {
  return Modal.confirm({
    title: title || 'Custom Modal',
    content: (
      <div
        style={{
          fontSize: 14,
          lineHeight: '22px',
          fontWeight: 400,
          color: '#595959',
          ...style,
        }}
      >
        {content}
      </div>
    ),
    onOk() {
      if (onOk) {
        onOk();
      }
    },
    onCancel() {
      if (onCancel) {
        onCancel();
      }
    },
    okText: okText ?? '确定',
    cancelText: cancleText ?? '取消',
    footer: (_, { OkBtn, CancelBtn }) => (
      <>
        <OkBtn />
        <CancelBtn />
      </>
    ),
    // 你可以继续添加更多的Ant Design Modal属性来满足需求
  });
}

export default customModal;
