import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCurrentTaskStore } from '@/hooks/useCurrentTask.ts';
import { useRealCurrentTaskId } from '@/hooks/useGlobalTaskId.ts';
import { useSiderStore } from '@/pages/Imgener/module/Sider/store';
import useLimit from '@/hooks/useLimit.ts';

export default function useCreateAIModel() {
  const { setRealCurrentTaskId } = useRealCurrentTaskId();
  const { createNewTask } = useCurrentTaskStore();
  const { loadList } = useSiderStore();
  const { limit } = useLimit();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      await loadList(limit.current);
    } catch {
      // message.error('获取任务列表失败');
    }
  }, [loadList, limit]);

  const createAIModel = useCallback(async () => {
    const realTaskMainId = uuidv4();
    setRealCurrentTaskId(realTaskMainId); // 清空全局真实任务id
    createNewTask(realTaskMainId, `商品款号${Math.random().toString().slice(-4)}`);
    navigate(`/imgener/material?taskMainId=${realTaskMainId}`);
    fetchData();
  }, [createNewTask, navigate, fetchData]);

  return { createAIModel };
}
