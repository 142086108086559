import { Input, Button, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './index.module.scss';
import LoginModel from '../model';

export default function FormBox() {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const validate = () => {
    // userName未满11位、未填userName
    if (userName.length !== 11 || userName === '') {
      message.info('请输入正确的手机号');
      return false;
    }

    // 密码不足6位
    if (password.length < 6) {
      message.info('请输入6位以上密码');
      return false;
    }

    return true;
  };

  const onLogin = async () => {
    try {
      await LoginModel.login(userName, password);
      message.success('登录成功');
      const timer = setTimeout(() => {
        navigate('/imgener');
        clearTimeout(timer);
      }, 300);
    } catch {
      message.info('输入的手机号或密码有误，请重新输入');
    }
  };

  const onClick = () => {
    if (validate()) {
      onLogin();
    }
  };

  const isDisabled = userName === '' || password === '';

  return (
    <div className={styles.formBox}>
      <div className={styles.logo} />

      <div className={styles.icon} />

      <div className={styles.contentWrapper}>
        <div className={styles.titleBar}>登录</div>

        <div className={styles.inputTitle}>手机号</div>
        <Input
          placeholder="请输入手机号"
          className={styles.input}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />

        <div className={`${styles.inputTitle} ${styles.second}`}>密码</div>

        <Input.Password
          placeholder="请输入密码"
          className={styles.input}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button
          type="primary" className={styles.btn}
          onClick={onClick}
          disabled={isDisabled}
        >
          立即登录
        </Button>
      </div>
    </div>
  );
}
