import { apiClient } from '@/utils/request';

/**
 * @description 获取款式信息
 * @author MY
 * @param params
 * @returns
 */
export const getItemInfo = async (params: any) => {
  try {
    const res = await apiClient.post('/task/getItemsByTask', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('更新款式任务失败', err);
  }
  return [];
};

/**
 * @description 获取款式信息
 * @author MY
 * @param params taskId
 * @returns
 */
export const getHistoryMaterialInfo = async (params: any) => {
  try {
    const res = await apiClient.post('/task/getAssetByTask', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('更新款式任务失败', err);
  }
  return [];
};

/**
 * 更新素材选择状态
 */
export const updateMaterialStatus = async (params: any) => {
  try {
    const res = await apiClient.post('/task/updateAssetByTask', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('更新款式任务失败', err);
  }
  return [];
};
