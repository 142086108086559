import type { RouteObject } from 'react-router';
import { EditModelInformation } from '@/pages/editModelInformation';
import MaterialManage from '@/pages/materialManage';
import { ImgenerView, LoginView } from '@/pages/Imgener';
import { AdView } from '@/pages/Imgener/module/Ad';
import { ModelGenerationEffect } from '@/pages/modelGenerationEffect';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <ImgenerView />,
  },
  {
    path: '/imgener',
    element: <ImgenerView />,
    children: [
      {
        path: '',
        element: <AdView />,
      },
      {
        path: 'material',
        element: <MaterialManage />,
      },
      {
        path: 'editModelInformation',
        element: <EditModelInformation />,
      },
      {
        path: 'modelGenerationEffect',
        element: <ModelGenerationEffect />,
      }
    ]
  },
  {
    path: '/login',
    element: <LoginView />
  }
];
export default routes;
