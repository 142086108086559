import { apiClient } from '@/utils/request';
import { TaskWithItemsDto, TaskDto } from './task.dto';

export const getRecordList = async (limit: number, offset: number) => {
  const data = await apiClient.post<{
    data: Array<TaskWithItemsDto>;
    total: number;
  }>('/task/getList', {
    data: {
      limit,
      offset,
    },
  });

  return data;
};

export const getRecordListByKeyword = async (keyword: string, limit: number, offset: number) => {
  const data = await apiClient.post<{
    data: TaskWithItemsDto[];
    total: number;
  }>('/task/searchTaskByOuterId', {
    data: {
      outerId: keyword,
      limit,
      offset,
    },
  });

  return data;
};

export const getRecordById = async (taskId: string) => {
  const data = await apiClient.post<TaskWithItemsDto>('/task/getTaskById', {
    data: {
      taskId
    },
  });

  return data;
};

export const deleteRecord = async (taskId: string) => {
  const data = await apiClient.post<TaskDto>('/task/updateTask', {
    data: {
      taskId,
      isDelete: '1',
    },
  });

  return data;
};

export const createRecord = async (taskId: string) => {
  const data = await apiClient.post<TaskDto>('/task/createMainTask', {
    data: {
      taskId,
    },
  });

  return data;
};
