import { removeItem, setItem } from '@/utils/storage';
import { fetchUserInfo } from './data';

class LoginModel {
  async login(userName: string, password: string) {
    try {
      const res = await fetchUserInfo({
        userName,
        password
      });
      await this.saveToken(res.data.authorization);
      return res.data.userInfo;
    } catch (error) {
      this.removeToken();
      throw error;
    }
  }

  async saveToken(token?: string) {
    setItem('authorization', token ?? '');
  }

  async removeToken() {
    removeItem('authorization');
  }
}

export default new LoginModel();
