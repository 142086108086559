import {
  getRecordList, deleteRecord, getRecordListByKeyword, createRecord, getRecordById
} from '../data';

export type TaskStatus =
  | 'in_reation'
  | 'creation_complete'
  | 'generation_complete'
  | 'in_generation';

export type Task = {
  coverUrl: string | null;
  enterpriseId: string;
  gmtCreated: string;
  gmtModified: string;
  id: number;
  modelId: number;
  modelUrl: string;
  readStatus: '0' | '1';
  sceneMainId: number;
  sceneUrl: string;
  status: string;
  stepPath: string;
  taskMainId: string;
  taskStatus: TaskStatus;
  taskUpdateTime: string;
  userId: string;
};

export type TaskWithItems = Task & { items: string[] };

class RecordListModel {
  async getListByPage(
    limit: number = 10,
    offset: number = 0,
  ): Promise<{ data: TaskWithItems[]; total: number }> {
    const data = await getRecordList(limit, offset);
    return data.data;
  }

  async deleteRecord(taskId: string): Promise<Task> {
    const data = await deleteRecord(taskId);
    return data.data;
  }

  async getListByKeyword(
    keyword: string,
    limit: number = 10,
    offset: number = 0,
  ): Promise<{ data: TaskWithItems[]; total: number }> {
    const data = await getRecordListByKeyword(keyword, limit, offset);
    return data.data;
  }

  async getRecordById(id: string): Promise<TaskWithItems> {
    const data = await getRecordById(id);
    return data.data;
  }

  async saveNewRecord(id: string): Promise<Task> {
    const data = await createRecord(id);
    return data.data;
  }

  getNext(task: Task) {
    if (task.taskStatus === 'in_reation') {
      return { needConfirm: true, next: task.stepPath };
    }

    return { needConfirm: false, next: '/imgener/modelGenerationEffect' };
  }
}

export default new RecordListModel();
