import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';
import { useCurrentTaskStore } from './useCurrentTask';
import { useRealCurrentTaskId } from './useGlobalTaskId';
import { isEmpty } from '@/utils';
import { createShapeTask } from '@/api/material';
import { getHistoryMaterialInfo, getItemInfo } from '@/api/task';
import { useFilesToOss } from './useFilesToOss';

export interface ItemType {
  /** 唯一id */
  id: number,
  /** 款号id */
  itemNumber: string,
  /** 款号链接 */
  itemLink: string
}
const initItemNumberCenter = [
  { id: 0, itemNumber: '', itemLink: '' },
];
export const useCreateItemNumberCenter = () => {
  const [search] = useSearchParams();
  const [itemNumberCenter, setItemNumberCenter] = useState<ItemType[]>(initItemNumberCenter);

  // 获取当前模板任务
  const { currentTask, setCurrentTask, setItems } = useCurrentTaskStore();
  const { setRealCurrentTaskId } = useRealCurrentTaskId();

  // 获取当前素材
  const { setFilesUpRes } = useFilesToOss();

  // 创造任务切换时，是否需要重新获取数据
  useEffect(() => {
    // 获取历史款号信息
    const fetchNewData = async (id) => {
      const newRes:any = await getItemInfo({ taskId: id });
      if (!isEmpty(newRes)) {
        const newList = newRes.map((shape) => ({
          id: shape.sortId,
          itemNumber: shape.itemId,
          itemLink: shape.productLink
        }));
        const uniqueIds = new Set();
        const uniqueList = newList.filter((item) => {
          if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            return true;
          }
          return false;
        });
        setItemNumberCenter(uniqueList);
        setItems(uniqueList.map((item) => item.itemNumber));
      } else {
        setItemNumberCenter(initItemNumberCenter);
      }
    };
    // 获取历史素材信息
    const fetchOldMaterialData = async (id) => {
      setFilesUpRes([]);
      const newRes:any = await getHistoryMaterialInfo({ taskId: id });
      if (!isEmpty(newRes)) {
        const historyList = newRes.map((item) => {
          const {
            id: itemId, isVideo, videoCutoutPath, originalImagePath, isSelectedForGeneration,
            videoCoverPicturePath, noCompressionPath
          } = item;
          return {
            id: itemId,
            uuidStr: uuidv4(),
            isVideo: isVideo === '1',
            status: 'done',
            resultFilesUrl: isVideo === '1' ? videoCutoutPath : originalImagePath,
            progress: 100,
            isCheck: isSelectedForGeneration === '1',
            coverPicture: videoCoverPicturePath,
            noCompressionUrl: noCompressionPath
          };
        });
        setFilesUpRes(historyList);
      } else {
        setFilesUpRes([]);
      }
    };
    fetchNewData(search.get('taskMainId'));
    fetchOldMaterialData(search.get('taskMainId'));
  }, [search.get('taskMainId')]);

  // 添加一条款号信息
  const addOneItemNumber = () => {
    setItemNumberCenter((preItem) => {
      const newId = [1, 2, 3, 4].find((id) => !preItem.some((item) => item.id === id));
      if (!newId) return preItem;
      return [...preItem,
        {
          id: newId,
          itemNumber: '',
          itemLink: ''
        }
      ];
    });
  };

  // 删除一个指定id的款号信息
  const deleteOneItemNumberById = (id: number) => {
    setItemNumberCenter(
      (preItem) => preItem.filter((item) => item.id !== id)
    );
    const newNumberList = itemNumberCenter.filter((item) => item.id !== id);
    createShapeTask({
      createShapeTaskId: search.get('taskMainId'),
      shapeTaskList: JSON.stringify(newNumberList),
    });
    const newCurrentTaskList = { ...currentTask, items: newNumberList.map((item) => item.itemNumber) };
    setCurrentTask(newCurrentTaskList as any);
  };

  // 统一更新款号信息的方法
  const updateItemNumberCenterList = (id: number, data: Partial<ItemType>) => {
    setItemNumberCenter((pre) => {
      return pre.map((preItem) => {
        if (preItem.id === id) {
          return {
            ...preItem,
            ...data
          };
        }
        return preItem;
      });
    });
  };

  // 更新任务方法
  const updateItemNumberTask = async (realTaskId, shapeList) => {
    await createShapeTask({
      createShapeTaskId: realTaskId,
      shapeTaskList: JSON.stringify(shapeList),
    });
  };
  // 创建任务方法
  const createItemNumberTask = async (modelTaskId, shapeList) => {
    const createTask:any = await createShapeTask({
      createShapeTaskId: modelTaskId,
      shapeTaskList: JSON.stringify(shapeList),
    });
    if (createTask.code === 200) {
      setRealCurrentTaskId(createTask.taskMainId);
      setCurrentTask({ ...currentTask, ...createTask.findTaskInfo, isTemp: true });
    }
  };

  return {
    itemNumberCenter,
    setItemNumberCenter,
    addOneItemNumber,
    deleteOneItemNumberById,
    updateItemNumberCenterList,
    createItemNumberTask,
    updateItemNumberTask
  };
};
