import React from 'react';
import './index.scss';
import { Checkbox, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DoMain } from '@/constants';
import { useFilesToOss } from '@/hooks/useFilesToOss';
import useFakeProgress from '@/hooks/useFakeProgress';
import { updateMaterialStatus } from '@/api/task.ts';

interface IProps {
  showType?: 'loading' | 'done' | 'noUpload';
  isVideo?: boolean;
  showFileUrl?: string
  isCheck?: boolean;
  onChangeCheck?: (checked: boolean) => void;
  preView?: () => void;
  progress?: number;
  warning?: boolean;
  id?: number;
}

const MaterialShow: React.FC<IProps> = React.memo((
  {
    showType = 'noUpload',
    isVideo = false,
    showFileUrl,
    isCheck = false,
    onChangeCheck,
    preView,
    progress = 20,
    id = 0,
    warning = false,
  }
) => {
  const { createUpTaskList } = useFilesToOss();
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (onChangeCheck) {
      onChangeCheck(e.target.checked);
      updateMaterialStatus({
        id,
        isSelectedForGeneration: e.target.checked ? '1' : '0',
      });
    }
  };

  const handlePewView = () => {
    if (preView) {
      preView();
    }
  };
  const progressPre = useFakeProgress(progress);
  return (
    <div className="material-show">
      {showType === 'noUpload' && (
      // <div className="no-upload" style={warning ? { background: '#FFF3F0', border: '1px solid #FF4D4A' } : {}}>
      <div className="no-upload" style={warning ? {} : {}}>
        <Upload
          multiple
          showUploadList={false}
          beforeUpload={(file) => { createUpTaskList(file); return false; }}
        >
          <div className="no-uploader">
            <PlusOutlined style={{ width: '18px', height: '18px', marginBottom: '12px' }} />
            <div>上传素材</div>
          </div>
        </Upload>
      </div>
      )}
      {showType === 'loading' && (
        <div className="loading">
          <img
            className="loading-pic"
            src={`${DoMain.BaseWebPic}/progress.png`}
            alt=""
          />
          <div>
            （
            {progressPre}
            %）
          </div>
          <div>素材上传中...</div>
        </div>
      )}
      {showType === 'done' && (
        <div className="done">
          <img
            className="show-video"
            src={showFileUrl || `${DoMain.BaseWebPic}/modelSecond.png`}
            alt=""
            onClick={handlePewView}
          />
          <div className="check-boxs">
            <Checkbox
              className="edit-check"
              checked={isCheck}
              onChange={handleCheckboxChange}
            />
          </div>
          {isVideo && (
            <div className="video-mask" onClick={handlePewView}>
              <img
                style={{ width: '24px', height: '24px' }}
                src={`${DoMain.BaseWebPic}/playIcon.png`}
                alt=""
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default MaterialShow;
