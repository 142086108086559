import React from 'react';
import './index.scss';
import { Upload } from 'antd';
import { DoMain } from '@/constants';
import { useFilesToOss } from '@/hooks/useFilesToOss';

const { Dragger } = Upload;
interface IProps {
  boxType?: 'default' | 'small';
  outStyle?: React.CSSProperties;
  bntDom?: React.ReactNode;
  tips?: string;
}

const FilesUploading: React.FC<IProps> = React.memo((
  {
    outStyle = {},
    boxType = 'default',
    bntDom = null,
    tips = ''
  }
) => {
  const { createUpTaskList } = useFilesToOss();
  return (
    <div className="files-uploading" style={{ ...outStyle }}>
      <Dragger
        // accept=".png,.jpg,.jpeg,.mp4,.mov"
        multiple
        showUploadList={false}
        beforeUpload={(file) => { createUpTaskList(file); return false; }}
      >
        {
          boxType === 'default'
            ? (
              <div className="updata-box">
                <img
                  src={`${DoMain.BaseWebPic}/filesUp.png`}
                  style={{ width: '80px', height: '80px', marginBottom: '12px' }}
                  alt=""
                />
                {bntDom}
                <ul className="up-tips">
                  <li>支持点击、拖拽上传；支持多选文件上传</li>
                  <li> 图片支持10MB以内的PNG/JPG/JPEG格式</li>
                  <li>视频支持100MB以内的MP4/MOV格式</li>
                </ul>
              </div>
            )
            : (
              <div className="small-box">
                <div>{tips}</div>
                {bntDom}
              </div>
            )
        }
      </Dragger>
    </div>
  );
});
export default FilesUploading;
