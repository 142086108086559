import { apiClient } from '@/utils/request';

/**
 * @description 创建款式任务
 * @author MY
 * @param params
 * @returns
 */
export const createShapeTask = async (params: any) => {
  try {
    const res = await apiClient.post('/shape/createShapeTask', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('创建款式任务失败', err);
  }
  return [];
};


/**
 * @description 创建款式任务
 * @author MY
 * @param params
 * @returns
 */
export const createMainTaskAndShape = async (params: any) => {
  try {
    const res = await apiClient.post('/shape/createMainTaskAndShape', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('创建主任务款式任务失败', err);
  }
  return [];
};

/**
 * @description 更新款式任务
 * @author MY
 * @param params
 * @returns
 */
export const updateShapeTask = async (params: any) => {
  try {
    const res = await apiClient.post('/shape/updateShapeTask', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('更新款式任务失败', err);
  }
  return [];
};

/**
 * @description 检测是否可以使用图片作为款式任务的图片素材
 * @author MY
 * @param params
 * @returns
 */
export const checkImgUse = async (params: any) => {
  try {
    const res = await apiClient.post('/shape/checkUseImage', {
      data: {
        ...params,
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('更新款式任务失败', err);
  }
  return [];
};

/**
 * @description 上传图片或者视频作为款式任务的素材
 * @param params
 * @returns
 */
export const uploadImgOrVideo = async (params: any) => {
  console.log(params, '上传参数');
  try {
    const res = await apiClient.post('/shape/uploadImage', {
      data: {
        ...params,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('上传图片或者视频作为款式任务的素材失败', err);
  }
  return [];
};
