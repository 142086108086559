import { apiClient } from '@/utils/request';
import UserInfo from './entity/UserInfo';

export const fetchUserInfo = async ({
  userName,
  password,
}: {
  userName: string
  password: string
}) => apiClient.post<{ userInfo: UserInfo, authorization: string }>('/auth/sign', {
  data: {
    phoneNumber: userName,
    password,
  }
});
