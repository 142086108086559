export default {
  upFilebnt: {
    width: '208px',
    height: '40px',
    borderRadius: '4px',
    fontSize: '16px',
    color: '#217EFF',
    background: '#FFFFFF',
    border: '1px solid #217EFF',
  },
};
