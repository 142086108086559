/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import customModal from '../../common/model';
import type { TaskStatus } from '../../model/recordList.model';
import HighlightKeyword from './HighlightKeyword';
import styles from './index.module.scss';
import { useSiderStore } from '../../store';
import generatingImg from '@/assets/image/generating.svg';

type IRecordItemViewProps = {
  id: string;
  coverUrl: string | null;
  items: string[] | null;
  taskStatus: TaskStatus;
  readStatus: string;
  isNew: boolean;
  onDropMenuClick: (id: string, isNew?: boolean) => void;
  onSelect: (id: string) => void;
};

function getTipContent(items: string[]) {
  return (
    <div
      style={{
        padding: '2px 0',
        color: '#fff',
        fontSize: '14px',
        width: '100%',
      }}
    >
      {items.map((item) => (
        <div
          key={item}
          style={{
            lineHeight: '22px',
            fontWeight: 400,
          }}
        >
          商品款号：
          {item}
        </div>
      ))}
    </div>
  );
}

const defaultImgUrl = ['https://q.aiyongtech.com/aiecElectron/assets/images/dci2.png'];

export default function RecordItem(props: IRecordItemViewProps) {
  const {
    coverUrl,
    taskStatus,
    readStatus,
    items,
    isNew,
    onDropMenuClick,
    onSelect,
    id,
  } = props;

  const { keyword, hasSearched } = useSiderStore();

  // const { currentTask } = useCurrentTaskStore();

  const domRef = useRef<HTMLDivElement>(null);

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          style={{
            width: 82,
            height: 32,
            display: 'flex',
            alignItems: 'center',
            color: '#262626',
            fontSize: 14,
          }}
        >
          <DeleteOutlined color="#000000" style={{ marginRight: 8 }} />
          删除
        </div>
      ),
    },
  ];
  const [search] = useSearchParams();

  const statusMap = {
    in_reation: '创作中',
    creation_complete: '',
    in_generation: <img
      src={generatingImg} alt=""
      className="rotate"
    />,
    generation_complete: '',
  };
  // 点击事件的回调 接收event对象
  const clickHandler = () => {
    onSelect(id);
    return true;
  };

  const deleteHandler = () => {
    customModal({
      title: `确定删除"${items?.join('、')}"的任务吗`,
      content:
        '删除后，此任务中所有商品款号下的生成素材将被删除，无法恢复，确定删除吗？',
      onOk: () => {
        onDropMenuClick(id, isNew);
      },
      onCancel: () => {},
    });
  };

  /**
   * 计算数组中字符串的长度
   */
  const getLength = (numberList) => {
    const totalLength = numberList.reduce((accumulator, currentValue, index) => {
      let newAccumulator = accumulator;
      if (index !== 0) {
        newAccumulator += 1; // 添加逗号的长度
      }
      return newAccumulator + currentValue.length;
    }, 0);
    return totalLength;
  };

  return (
    <div
      className={`${styles['record-item']}${search.get('taskMainId') === id ? ' active' : ''}`}
      ref={domRef}
    >
      <div className={styles.itemContent}>
        <div className={styles.itemContentListItem} onClick={clickHandler}>
          <div className={styles.cover}>
            <img
              src={
              coverUrl
              || defaultImgUrl[0]
            }
              alt=""
            />
          </div>

          <div className={styles.items}>
            {readStatus === '0' && taskStatus === 'generation_complete' && (
            <div className={styles.readTip} />
            )}
            {items && items.length > 0 && (
            <Tooltip
              title={getLength(items) > 17 ? getTipContent(items) : ''}
              placement="bottom"
              getPopupContainer={() => domRef.current!}
            >
              <div className={styles.text}>
                <HighlightKeyword
                  title={items?.join('、')}
                  keyword={hasSearched ? keyword : ''}
                />
              </div>
            </Tooltip>
            )}
          </div>

          <div className={styles.status}>{statusMap[taskStatus]}</div>
        </div>
        <div className={styles.dropContent}>
          <Dropdown
            menu={{
              items: menuItems,
              className: 'task-dmenu',
              onClick: deleteHandler,
            }}
            placement="bottom"
          >
            <div
              className={styles.drop}
            >
              ...
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
