/* eslint-disable react/no-array-index-key */
export default function HighlightKeyword({
  title,
  keyword,
}: {
  title: string;
  keyword: string;
}) {
  if (!keyword.trim()) {
    return <span>{title}</span>;
  }

  const regex = new RegExp(`(${keyword})`, 'gi');
  const parts = title.split(regex);

  return (
    <span>
      {parts.map((part, index) => (regex.test(part) ? (
        <span key={index} style={{ color: '#217EFF' }}>
          {part}
        </span>
      ) : (
        part
      )),)}
    </span>
  );
}
