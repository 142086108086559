import { useEffect, useState } from 'react';
import './index.scss';
import { InfoCircleOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { message } from 'antd';
import {
  createGenerateList,
  getAllModelInfo,
  getHistoryTaskInfo,
  getMainTaskInfo, updateBatchNumber, updateMainTaskPath,
} from '@/api/modelInfo.ts';
import { ModelInfoData } from '@/pages/editModelInformation/const.ts';
import { isEmpty } from '@/utils';
import modelSelect from '@/assets/image/model_select.png';
import { useModelEdit } from '@/hooks/useModelEdit.ts';
import ImgViewsPlay from '@/components/ImgViewsPlay';
import PosePlay from '@/components/PosePlay';
import { DoMain } from '@/constants';
import { useCurrentTaskStore } from '@/hooks/useCurrentTask';

export function EditModelInformation() {
  const [modelInfo, setModelInfo] = useState<ModelInfoData[]>([]);
  const [modelId, setModelId] = useState<number>(0);
  const [batchList, setBatchList] = useState<any[]>([]);
  const [batchNumber, setBatchNumber] = useState<number>(0);
  const [generateStatus, setGenerateStatus] = useState<boolean>(false);
  const [btnStatus, setBtnStatus] = useState<boolean>(true);

  const location = useLocation();
  const params = location.state;
  const [query] = useSearchParams();

  const { currentTask, setCurrentTask } = useCurrentTaskStore();

  const {
    selectModelInfo,
    setSelectModelInfo,
    mainSceneInfo,
    subSceneInfo,
    poseInfo,
  } = useModelEdit();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllModelInfo();
        if (!isEmpty(res)) {
          setModelInfo(res);
          const historyList = await getHistoryTaskInfo({
            taskMainId: query.get('taskMainId'),
          });
          // 获取模特历史记录
          if (!isEmpty(historyList)) {
            const modelObj = res.find((item: any) => item.id === historyList?.modelId);
            setModelId(historyList?.modelId);
            setGenerateStatus(true);
            setSelectModelInfo(modelObj as any);
          } else {
            setModelId(1);
            const modelObj = res.find((item: any) => item.id === 1);
            setSelectModelInfo(modelObj as any);
          }
        }
        const mainTaskInfo = await getMainTaskInfo({
          taskMainId: query.get('taskMainId'),
        });
        if (!isEmpty(mainTaskInfo)) {
          const newArr: any[] = isEmpty(mainTaskInfo[0].batchNumber) ? [] : JSON.parse(mainTaskInfo[0].batchNumber);
          setBatchList(newArr);
          setBatchNumber(isEmpty(mainTaskInfo[0].batchNumber) ? 0 : Math.max(...newArr));
        }
        // 通过路由获取model
        if (!isEmpty(params) && !isEmpty(params.modelId)) {
          setModelId(params.modelId);
          const modelObj = res.find((item: any) => item.id === params.modelId);
          setSelectModelInfo(modelObj as any);
        }
      } catch (error) {
        console.error('获取模特信息时出错:', error);
        // 在这里处理错误情况
      }
    };

    fetchData();
  }, []);

  /**
   * 编辑模特信息
   */
  const editModelInfo = (selectModel: any) => {
    setModelId(selectModel.id);
    setSelectModelInfo(selectModel);
  };

  /**
   * 换算坐标系参数
   */
  const convertToDict = (coordinates) => {
    // 检查坐标数量
    if (coordinates.length !== 4) {
      return '需要提供四个坐标点';
    }

    // 解析坐标
    const xCoords = coordinates.map((point) => point[0]);
    const yCoords = coordinates.map((point) => point[1]);

    // 计算边界
    const left = Math.min(...xCoords);
    const right = Math.max(...xCoords);
    const top = Math.min(...yCoords);
    const bottom = Math.max(...yCoords);

    // 构建字典
    const boundsDict = {
      left,
      right,
      top,
      bottom
    };

    return JSON.stringify(boundsDict);
  };
  /**
   * 创建生成队列
   */
  const createGenerateQueue = async (data: any) => {
    const poseIdList: any[] = [];
    const newArr = await data.poseInfo.map(async (item:any, index) => {
      // eslint-disable-next-line no-param-reassign
      item.id = index + 1;
      poseIdList.push(item.assetTaskId as string);
      return item;
    });
    const newArray = Array(newArr.length).fill(null).flatMap(() => data.poseInfo);
    // 随机打乱 subSceneInfo 数组顺序
    const shuffledSubSceneInfo = data.subSceneInfo.sort(() => Math.random() - 0.5);
    // 创建一个 Set 来存储去重后的 sceneUrl
    const uniqueSceneUrlsSet = new Set();
    // 随机去重的 sceneUrl 数组
    const uniqueSceneInfo: any = [];
    for (let i = 0; i < shuffledSubSceneInfo.length; i++) {
      const sceneUrl = shuffledSubSceneInfo[i].sceneImageSource;
      if (!uniqueSceneUrlsSet.has(sceneUrl)) {
        uniqueSceneUrlsSet.add(sceneUrl);
        uniqueSceneInfo.push(shuffledSubSceneInfo[i]);
      }
    }
    // 同时调用5次接口,要求同时调用
    const promises = newArray.map(async (item, index) => {
      const randomIndex = Math.floor(Math.random() * uniqueSceneInfo.length);
      const referImage = uniqueSceneInfo[randomIndex].sceneImageSource;
      // todo 子表同步好放开
      const { sceneRectangle, reboderDenoisingStrength, denoisingStrength } = uniqueSceneInfo[randomIndex];
      // const { reboderDenoisingStrength, denoisingStrength } = uniqueSceneInfo[randomIndex];
      uniqueSceneInfo.splice(randomIndex, 1);
      const keyIndex = uuidv4(); // 使用uuid生成唯一标识符
      const subSceneIndex = !isEmpty(referImage) ? 0 : Math.floor(index / 2);
      const dataParams = {
        taskMainId: data.taskMainId,
        avatarPrompt: data.selectModelInfo.avatarPrompt,
        facePrompt: data.selectModelInfo.facePrompt,
        modelId: data.modelId,
        assetId: item.id.toString(),
        batchNumber: JSON.stringify(data.batchNumber + 1),
        productImage: item.originalImagePath,
        referImage: !isEmpty(referImage) ? referImage : data.subSceneInfo[subSceneIndex]?.sceneImageSource,
        maskImage: item.cutoutImagePath || '',
        sceneName: data.mainSceneInfo.sceneName,
        modelName: data.selectModelInfo.modelName,
        sceneUrl: data.mainSceneInfo.sceneUrl,
        modelUrl: data.selectModelInfo.avatarImg,
        sceneMainId: JSON.stringify(data.mainSceneInfo.id),
        poseList: JSON.stringify(poseIdList),
        assetUrl: item.originalImagePath,
        sceneSubId: keyIndex,
        assetTaskId: item.assetTaskId,
        whiteBackground: item.whiteBackground || '',
        productPos: convertToDict(JSON.parse(!isEmpty(sceneRectangle) ? sceneRectangle : data.subSceneInfo[subSceneIndex]?.sceneRectangle)),
        // productPos: '{"left":109,"top":151,"right":663,"bottom":982}',
        denoisingStrength: JSON.stringify(!isEmpty(denoisingStrength) ? denoisingStrength : data.subSceneInfo[subSceneIndex]?.denoisingStrength),
        reborderDenisong: JSON.stringify(!isEmpty(reboderDenoisingStrength) ? reboderDenoisingStrength : data.subSceneInfo[subSceneIndex]?.reboderDenoisingStrength),
      };
      return createGenerateList(dataParams);
    });
    const CreateGen = await Promise.all(promises);
    return CreateGen;
  };

  /**
   * 跳转到模特生成效果页面
   */
  const goToModelGenerate = async () => {
    // if (isEmpty(poseInfo) || (poseInfo.length > 5 || poseInfo.length < 3)) {
    //   message.error('请选择3-5个姿态');
    //   return;
    // }
    if (isEmpty(poseInfo)) {
      message.error('请选择姿势');
      return;
    }
    setBtnStatus(false);
    await createGenerateQueue({
      taskMainId: query.get('taskMainId'),
      poseInfo,
      subSceneInfo,
      selectModelInfo,
      modelId,
      batchNumber,
      mainSceneInfo,
    });
    const data = {
      taskMainId: query.get('taskMainId'),
      batchNumber: JSON.stringify([...batchList, batchNumber + 1])
    };
    // @ts-expect-error coverUrl类型有问题？不应该出现undefined。暂时忽略
    setCurrentTask({ ...currentTask, taskStatus: 'in_generation' });

    updateBatchNumber(data);
    updateMainTaskPath({
      taskId: query.get('taskMainId'),
      taskStatus: 'in_generation',
      stapPath: '/imgener/modelGenerationEffect',
    });
    // 路由跳转页面
    navigate(`/imgener/modelGenerationEffect?taskMainId=${params?.taskMainId || query.get('taskMainId')}`, {
      state: {
        batchList: [...batchList, batchNumber + 1],
        batchNumber: batchNumber + 1,
        taskMainId: query.get('taskMainId')
      }
    });
  };

  /**
   * 跳转结果页
   */
  const goToResultPage = () => {
    updateMainTaskPath({
      taskId: query.get('taskMainId'),
      stapPath: '/imgener/modelGenerationEffect',
    });
    navigate(`/imgener/modelGenerationEffect?taskMainId=${query.get('taskMainId')}`, {
      state: {
        taskMainId: query.get('taskMainId'),
      }
    });
  };

  return (
    <div className="edit-model-information">
      <div className="model-info-mid-content">
        <div className="model-generation-mid-content-top">
          <div className="mid-content-title">
            {/* <div className="title-left" /> */}
            <LeftOutlined onClick={() => navigate(`/imgener/material?taskMainId=${query.get('taskMainId')}`)} />
            <div className="title-right">编辑模特信息</div>
          </div>
          <img
            className="starBack"
            src={`${DoMain.BaseWebPic}/starBackBlur.png`}
            alt=""
          />
          {
            generateStatus && (
            <div className="mid-content-restart-btn" onClick={goToResultPage}>
              查看生成结果
            </div>
            )
          }
        </div>
        <div className="mid-content-model">
          <div className="select-model-title">
            选择模特
            <div className="select-model-title-fu">（模特身高150cm）</div>
          </div>
          <div className="select-model-content">
            {
              modelInfo.map((item: ModelInfoData) => (
                <div
                  className="select-model-item" key={item.id}
                  onClick={() => editModelInfo(item)}
                >
                  <div className="select-model-item-img">
                    {
                      modelId === item.id && (
                      <img
                        src={modelSelect} alt=""
                        className="select-icon"
                      />
                      )
                    }
                    <img
                      src={item.avatarImg} className={modelId === item.id ? 'model-img-item-active' : 'model-img-item'}
                      alt=""
                    />
                  </div>
                  <div className="select-model-item-name">{item.modelName}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="mid-content-scen-title">选择模特场景</div>
        <div className="mid-content-scen">
          <ImgViewsPlay />
        </div>
        <div className="mid-content-pose-title">
          <div>选择模特姿势</div>
          <div className="pose-title-icon"><InfoCircleOutlined /></div>
          <div className="pose-title-explain">模特姿势需要一定的处理时间，请耐心等待（等待过程中支持同步进行其他任务哦，此任务会自动保存）</div>
        </div>
        <div className="mid-content-pose">
          <PosePlay />
        </div>
      </div>
      <div className="start-click">
        <div
          className="start-click-btn" style={btnStatus ? { background: '#1890ff' } : { background: '#1890ff' }}
          onClick={() => {
            if (btnStatus) { goToModelGenerate(); }
          }}
        >
          {btnStatus ? '开始创作' : <LoadingOutlined style={{ fontSize: 16 }} spin />}
        </div>
      </div>
    </div>
  );
}
