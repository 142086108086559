import { Button } from 'antd';
import styles from './index.module.scss';
import adimg from '@/assets/image/adimg.png';
import useCreateAIModel from '@/hooks/useCreateAIModel.ts';

export default function AdView() {
  const { createAIModel } = useCreateAIModel();
  return (
    <div className={styles.ad}>
      <div className={styles.wrapper}>
        <img src={adimg} alt="" />
      </div>

      <Button
        type="primary"
        className={styles.btn}
        onClick={() => createAIModel()}
      >
        AI模特图创作
      </Button>

    </div>
  );
}
