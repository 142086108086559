import { Button, message } from 'antd';
import moment from 'moment';
import styles from './index.module.scss';

type IImgPreviewerProps = {
  leftImageUrl: string;
  rightImageUrl: string;
  effectImageInfo: {
    modelName: string,
    modelUrl: string,
    sceneName: string,
    sceneUrl: string,
    gmtCreated: string,
    itemIds: string[] | null
  },
  onReEdit: (item: any) => void;
};

function timeFormatter(time: string) {
  const utcDateTime = moment.utc(time);
  const localDateTime = utcDateTime.local();
  const formattedDateTime = localDateTime.format('YYYY-MM-DD hh:mm:ss');
  return formattedDateTime;
}

export default function ImgPreviewer({
  leftImageUrl,
  rightImageUrl,
  effectImageInfo,
  onReEdit,
}: IImgPreviewerProps) {
  function downloadImage(imageUrl, fileName) {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // 下载开始
        const blobUrl = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName; // 指定下载文件名
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl);
        message.success('下载成功！');
      })
      .catch(() => message.error('下载失败'));
  }

  function onClickDownload() {
    const item = (effectImageInfo.itemIds && effectImageInfo.itemIds[0]) ?? '';
    const time = timeFormatter(effectImageInfo.gmtCreated).split(' ')[0].slice(2).replaceAll('-', '');
    const fileName = `${item}_${time}`;
    downloadImage(rightImageUrl, fileName);
  }

  return (
    <div className={styles.card}>
      <div className={styles.imagesContainer}>
        <div className={styles.imageWrapper}>
          <img src={leftImageUrl} alt="素材图" />
          <div className={styles.imageLabel}>素材图</div>
        </div>
        <div className={styles.imageWrapper}>
          <img src={rightImageUrl} alt="效果图" />
          <div className={styles.imageLabel}>效果图</div>
          {effectImageInfo && (
          <div className={styles.imageInfo}>
            <div className={styles.infoContent}>
              <div className={styles.infoText} style={{ color: '#595959' }}>
                参数信息
              </div>
              <div className={styles.infoText}>
                {timeFormatter(effectImageInfo?.gmtCreated)}
              </div>
              <div className={styles.infoModel}>
                <div className={styles.infoModelContentBox}>
                  <img src={effectImageInfo?.modelUrl} alt="模型图" />
                  <span className={styles.infoModelName}>{effectImageInfo.modelName}</span>
                </div>

                <div className={styles.separator} />

                <div className={styles.infoModelContentBox}>
                  <img src={effectImageInfo?.sceneUrl} alt="场景图" />
                  <span className={styles.infoModelName}>{effectImageInfo.sceneName}</span>
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={`${styles.button} ${styles.left}`} type="primary"
          onClick={() => onClickDownload()}
        >
          下载效果图
        </Button>
        <Button className={`${styles.button} ${styles.right}`} onClick={onReEdit}>再次编辑</Button>
      </div>
    </div>
  );
}
