import { apiClient } from '@/utils/request';
import { ApiResponse, ModelInfoData } from '@/pages/editModelInformation/const.ts';

export const getAllModelInfo = async (): Promise<ModelInfoData[]> => {
  try {
    const res = await apiClient.get('/modelInfo/getAllModel');
    if (res.code === 200) {
      return res.data as ModelInfoData[];
    }
  } catch (err) {
    console.log('获取模特信息失败：', err);
  }
  return [];
};

export const getAllMainSceneInfo = async (params: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.get('/scene/allMainScenes', { params });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('获取模特信息失败：', err);
  }
  return [];
};

/**
 * 获取主任务信息
 * @param params
 */
export const getAllPoseInfo = async (params: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.get('/asset/getAllList', { params });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('获取模特信息失败：', err);
  }
  return [];
};

/**
 * 获取主任务信息
 * @param params
 */
export const getMainTaskInfo = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/searchBatchByMainId', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('获取主任务信息失败：', err);
  }
  return [];
};

/**
 * 获取最新历史数据
 */
export const getHistoryTaskInfo = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/getLatestTaskData', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log();
  }
  return new Error();
};

/**
 * 获取单个子任务信息
 */
export const getSubTaskInfo = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/searchBatchParamByMainIdAndBatchId', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('子任务获取失败', err);
  }
  return [];
};
/**
 * 获取所有生图任务
 */
export const getAllGenerateList = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/searchTaskByMainId', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('生成列表获取失败', err);
  }
  return [];
};
/**
 * 获取子场景信息
 */
export const getSubSceneInfo = async (params: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.get('/scene/subScene', { params });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('子场景获取失败', err);
  }
  return [];
};

/**
 * 创建生成列表
 */
export const createGenerateList = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/uploadImg', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('创建生成列表失败', err);
  }
  return [];
};

/**
 * 创建生成列表
 */
export const updateBatchNumber = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/updateBatchNumber', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('创建生成列表失败', err);
  }
  return [];
};

/**
 * 更新主任务路径
 */
export const updateMainTaskPath = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/updateTask', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('创建生成列表失败', err);
  }
  return [];
};

/**
 * 查询未完成的任务状态
 */
export const getTaskStatus = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/updateAllSubTaskReadStatus', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('查询未完成的任务状态失败', err);
  }
  return [];
};

/**
 * 抠图接口
 */
export const getCutImg = async (data: any): Promise<any> => {
  try {
    const res: ApiResponse = await apiClient.post('/task/cutImage', { data });
    if (res.code === 200) {
      return res.data;
    }
  } catch (err) {
    console.log('抠图接口失败', err);
  }
  return [];
};
