import { useNavigate } from 'react-router';
import './index.scss';
import { Progress } from 'antd';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { isEmpty, resizeOSSImage } from '@/utils';
import { useRealCurrentTaskId } from '@/hooks/useGlobalTaskId';

type IGenerateImgList = {
  generateImgList: any;
  onImgClick: (item: any) => void;
};

export function GenerateImgList({ generateImgList, onImgClick }: IGenerateImgList) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const { realCurrentTaskId } = useRealCurrentTaskId();

  const chageImg = (item:any) => {
    onImgClick(item);
  };

  /**
   * 再次编辑
   */
  const reEdit = (item: any) => {
    navigate(`/imgener/editModelInformation?taskMainId=${realCurrentTaskId}`, {
      state: {
        taskMainId: params?.taskMainId || 'fc624b83-268f93673281',
        poseList: item[0].poseList,
        sceneMainId: item[0].sceneMainId,
        modelId: item[0].modelId,
      }
    });
  };

  return (
    <div className="generate-img-object">
      {
        !isEmpty(generateImgList) && Object.keys(generateImgList).map((key: any) => {
          return (
            <div className="generate-img-list" key={key}>
              {
                    key === '1' && <div className="genrate-time" style={{ marginLeft: '5px' }}>{moment(generateImgList[key][0]?.gmtCreated).format('YYYY-MM-DD HH:mm:ss')}</div>
              }
              {
                  key === '1' && (
                  <div className="generate-info">
                    <div className="generate-info-left">
                      <div className="info-left-model">
                        <img
                          className="left-model-img" src={generateImgList[key][0]?.modelUrl}
                          alt=""
                        />
                        <div className="left-model-name">{generateImgList[key][0]?.modelName}</div>
                      </div>
                      <div className="info-left-line" />
                      <div className="info-left-scene">
                        <img
                          className="left-model-img" src={generateImgList[key][0]?.sceneUrl}
                          alt=""
                        />
                        <div className="left-model-name">{generateImgList[key][0]?.sceneName}</div>
                      </div>
                      <div className="info-left-line" />
                      <div className="info-left-content">
                        <div className="left-model-name">
                          创建人：
                          {generateImgList[key][0]?.userName}
                        </div>
                      </div>
                    </div>
                    <div className="generate-info-right" onClick={() => reEdit(generateImgList[key])}>
                      再次编辑
                    </div>
                  </div>
                  )
                }

              <div className="generate-imgs">
                {
                    generateImgList[key].map((item) => {
                      if (isEmpty(item)) {
                        return null;
                      }
                      return (
                        <div
                          className="generate-img-item" key={item?.id}
                          onClick={() => chageImg(item)}
                        >
                          {
                            !isEmpty(item?.resultUrl) ? (
                              <img
                                src={resizeOSSImage(item?.resultUrl, 160, 214)} alt=""
                                className="generate-img-item-img"
                              />
                            ) : (
                              <div className="generate-img-item-img generate-img-item-loading" style={{ backgroundColor: '#F2F4F5' }}>
                                <Progress
                                  type="circle"
                                  size={80}
                                  percent={Math.round((item?.generateTaskProgress ?? 0) * 100)}
                                />
                              </div>
                            )
                          }

                        </div>
                      );
                    })
                  }
              </div>
            </div>
          );
        })
          }
    </div>

  );
}
