import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  realCurrentTaskId: '',
};
const { useGlobalState } = createGlobalState(initialState);
/**
 * @description 全局主任务id
 * @author MY
 * @date 2023-08-28 12:08:11
 */
export const useRealCurrentTaskId = () => {
  const [realCurrentTaskId, setRealCurrentTaskId] = useGlobalState('realCurrentTaskId');
  return { realCurrentTaskId, setRealCurrentTaskId };
};
