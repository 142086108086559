import { Outlet } from 'react-router';
import ImgenerLayout from '../module/layout';
import { SiderView } from '../module/Sider';
import './index.scss';

export default function ImgenerView() {
  return (
    <ImgenerLayout SiderChildren={<SiderView />} ContentChildren={<Outlet />} />
  );
}
