import { createGlobalState } from 'react-hooks-global-state';
import { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { getAllPoseInfo } from '@/api/modelInfo.ts';

const initialState = {
  /** 姿势数组 */
  poseList: [],
  isLoading: false,
  isPolling: true,
  taskHistoryMainId: '',
};
const { useGlobalState } = createGlobalState(initialState);

let isHistoryPollingRef = true;

/**
 * @description 模特编辑参数
 */
export const usePoseList = (taskMainId: string) => {
  const [poseList, setPoseList] = useGlobalState('poseList');
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [taskHistoryMainId, setTaskHistoryMainId] = useGlobalState('taskHistoryMainId');
  const openBtn = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const poseArr: any = await getAllPoseInfo({
          taskMainId
        });
        if (!isEmpty(poseArr.subAssetsList)) {
          const poseOriginList: any = [];
          // 拿到poseArr中的
          poseArr.subAssetsList.forEach((item: any) => {
            // eslint-disable-next-line no-param-reassign
            item.isCheck = false;
            poseOriginList.push(item);
          });
          if (isEmpty(poseList) || taskMainId !== taskHistoryMainId) {
            setTaskHistoryMainId(taskMainId);
            setPoseList(poseOriginList);
          } else {
            const uniqueObjects = poseOriginList.filter((obj1: any) => {
              return !poseList.some((obj2: any) => obj2.assetTaskId === obj1.assetTaskId && obj2.assetTaskId === obj1.assetTaskId);
            });
            // @ts-expect-error 类型不对
            setPoseList([...poseList, ...uniqueObjects]);
          }
        }
        // 检查任务状态，如果已完成，则停止轮询
        if (poseArr?.taskStatus === 'done' && taskMainId === taskHistoryMainId) {
          isHistoryPollingRef = false;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (openBtn.current || taskMainId !== taskHistoryMainId) {
      if (taskMainId !== taskHistoryMainId) {
        setTaskHistoryMainId(taskMainId);
        setPoseList([]);
      }
      fetchData();
      openBtn.current = false;
    }

    const interval = setInterval(() => {
      if (isHistoryPollingRef || taskMainId !== taskHistoryMainId) {
        fetchData();
      } else {
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [poseList, taskMainId, setPoseList, setIsLoading, taskHistoryMainId]);

  return {
    poseList,
    isLoading,
  };
};
