import styles from './index.module.scss';
import logo from '@/assets/image/logo.svg';

export default function LoginHeader() {
  return (
    <div className={styles.header}>
      <img src={logo} alt="" />
    </div>
  );
}
