import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { getAllMainSceneInfo, getHistoryTaskInfo, getSubSceneInfo } from '@/api/modelInfo.ts';
import 'react-lazy-load-image-component/src/effects/blur.css';
import modelSelect from '@/assets/image/model_select.png';
// import returnIcon from '@/assets/image/return.png';
// import nextIcon from '@/assets/image/next.png';
import './index.scss';
import { useModelEdit } from '@/hooks/useModelEdit.ts';

type ImageSource = {
  id: number;
  sceneUrl: string;
  sceneName: string;
};

function ImgViewsPlay() {
  const [pageNo] = useState(1);
  const [clickIndex, setClickIndex] = useState(-1);
  const [sceneList, setSceneList] = useState<ImageSource[]>([]);
  // const [setTotal] = useState(0);
  const {
    setMainSceneInfo,
    setSubSceneInfo,
  } = useModelEdit();

  const location = useLocation();
  const params = location.state;

  /**
     * 获取子场景信息
     */
  const getSubSceneList = async (mainSceneId: number) => {
    try {
      const subSceneRes = await getSubSceneInfo({
        id: mainSceneId,
      });
      if (!isEmpty(subSceneRes)) {
        setSubSceneInfo(subSceneRes);
      }
    } catch (error) {
      console.error('获取子场景信息时出错:', error);
    }
  };

  const fetchData = async () => {
    try {
      const sceneRes = await getAllMainSceneInfo({
        page: pageNo,
        limit: 10,
      });
      if (!isEmpty(sceneRes.data)) {
        setSceneList(sceneRes.data);
        // setTotal(sceneRes.total);
        const historyList = await getHistoryTaskInfo({
          taskMainId: params?.taskMainId
        });
        if (!isEmpty(historyList)) {
          setClickIndex(Number(historyList.sceneMainId));
          const mainScene = sceneRes.data.find((item:any) => item.id === Number(historyList.sceneMainId));
          setMainSceneInfo(mainScene as any);
          getSubSceneList(historyList.sceneMainId);
        } else {
          setClickIndex(1);
          const mainScene = sceneRes.data.find((item:any) => item.id === 1);
          setMainSceneInfo(mainScene as any);
          getSubSceneList(1);
        }

        // 通过路由获取model
        if (!isEmpty(params) && !isEmpty(params.sceneMainId)) {
          setClickIndex(Number(params.sceneMainId));
          const mainScene = sceneRes.data.find((item:any) => item.id === Number(params.sceneMainId));
          setMainSceneInfo(mainScene as any);
          getSubSceneList(Number(params.sceneMainId));
        }
      }
    } catch (error) {
      console.error('获取场景信息时出错:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo]);

  // const goToNext = async () => {
  //   if (pageNo * 10 < total) {
  //     setPageNo(pageNo + 1);
  //   }
  // };
  //
  // const goToPrevious = async () => {
  //   if (pageNo > 1) {
  //     setPageNo(pageNo - 1);
  //   }
  // };

  /**
     *选中图片
     */
  const handleClickImg = async (mainScene: any) => {
    setClickIndex(mainScene.id);
    setMainSceneInfo(mainScene);
    await getSubSceneList(mainScene.id);
  };

  return (
    <div className="image-slider">
      {/* <div className="prev-button" onClick={goToPrevious}> */}
      {/*  <div className="prev-button-return" style={pageNo === 1 ? { backgroundColor: '#EBEBEB' } : {}}> */}
      {/*    <img */}
      {/*      src={returnIcon} alt="" */}
      {/*      className="return-icon" */}
      {/*    /> */}
      {/*  </div> */}
      {/* </div> */}
      <div className="image-container">
        {sceneList.map((image) => (
          <div className="image-container-item" key={image.id}>
            <img
              key={image.id}
              alt=""
              src={image.sceneUrl}
              className={image.id === clickIndex ? 'scene-img-select' : 'scene-img'}
              style={{ width: '80px', height: '80px' }}
              onClick={() => handleClickImg(image)}
            />
            {
                            image.id === clickIndex && (
                            <img
                              src={modelSelect} alt=""
                              className="select-icon"
                            />
                            )
                        }
            <div className="scene-img-name">{image.sceneName || ''}</div>
          </div>
        ))}
      </div>
      {/* <div className="next-button" onClick={goToNext}> */}
      {/*  <div className="next-button-next" style={pageNo * 10 >= total ? { backgroundColor: '#EBEBEB' } : {}}> */}
      {/*    <img */}
      {/*      src={nextIcon} */}
      {/*      alt="" */}
      {/*      className="next-icon" */}
      {/*    /> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
}

export default ImgViewsPlay;
