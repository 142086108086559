import ApiClient from './client';

/**
 * @description: 创建一个请求实例
 * @param {string} baseURL
 * @return {ApiClient}
 * @example apiClient.get<number[]>('/data').then((responseData) => {
 *      console.log(responseData)
 * })
 */
const apiClient = new ApiClient({ baseURL: import.meta.env.VITE_BASE_API });

export { apiClient };
