import { useEffect, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Progress } from 'antd';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import modelSelect from '@/assets/image/shape_select_icon.png';
import './index.scss';
import { usePoseList } from '@/hooks/usePoseList.ts';
import returnIcon from '@/assets/image/return.png';
import nextIcon from '@/assets/image/next.png';
import { isEmpty, resizeOSSImage } from '@/utils';
import { useModelEdit } from '@/hooks/useModelEdit.ts';
import Overlay from '../Overlay';
import { getHistoryTaskInfo } from '@/api/modelInfo.ts';

const targetProgress = [90, 88, 67];
function PosePlay() {
  const [startIndex, setStartIndex] = useState(0);
  const [clickIndex, setClickIndex] = useState<string[]>([]);

  const location = useLocation();
  const params = location.state;

  const [query] = useSearchParams();

  const { poseList } = usePoseList(query.get('taskMainId') || '');
  const [progress, setProgress] = useState([10, 30, 30]); // 每个加载项的百分比// 每个加载项的目标百分比
  const { setPoseInfo } = useModelEdit();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // 判断是不是历史参数
      if (!isEmpty(params?.poseList)) {
        const newClickIndex = JSON.parse(params.poseList);
        setClickIndex(newClickIndex);
        const newList: any = [];
        newClickIndex.forEach((item: any) => {
          const newItem = poseList.filter((imgItem: any) => imgItem.assetTaskId === item);
          newList.push(newItem[0]);
        });
        setPoseInfo(newList);
      } else {
        const historyList = await getHistoryTaskInfo({
          taskMainId: query.get('taskMainId'),
        });
        if (!isEmpty(historyList)) {
          const newPoseList = JSON.parse(historyList.poseList);
          const selectPostList = poseList.filter((item: any) => newPoseList.includes(item.assetTaskId));
          setPoseInfo(selectPostList);
          setClickIndex(newPoseList);
        }
      }
    };
    fetchData();
  }, [poseList, query.get('taskMainId')]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let updatedProgress = [...progress];
      let allComplete = true;

      updatedProgress = updatedProgress.map((value, index) => {
        if (value < targetProgress[index]) {
          allComplete = false;
          return value + 1;
        }
        return value;
      });

      setProgress(updatedProgress);

      if (allComplete) {
        clearInterval(intervalId);
      }
    }, 100); // 每100毫秒递增一次

    return () => {
      clearInterval(intervalId); // 在组件卸载时清除定时器
    };
  }, [progress]); // 空数组表示只在组件加载时运行一次

  const goToPrevious = () => {
    setStartIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 6 : 0));
  };

  const goToNext = () => {
    setStartIndex((prevIndex) => (prevIndex < poseList.length - 6 ? prevIndex + 6 : prevIndex));
  };

  /**
     *选中图片
     */
  const handleClickImg = (img: any) => {
    const newClickIndex = clickIndex.includes(img.assetTaskId)
      ? clickIndex.filter((item: string) => item !== img.assetTaskId)
      : [...clickIndex, img.assetTaskId];
    setClickIndex(newClickIndex);
    const newList: any = [];
    newClickIndex.forEach((item: any) => {
      const newItem = poseList.filter((imgItem: any) => imgItem.assetTaskId === item);
      newList.push(newItem[0]);
    });
    setPoseInfo(newList);
  };
  // 跳转回素材页
  const goMaterial = () => {
    const formPath = 'editModelInformation';
    navigate(`/imgener/material?formPath=${formPath}`);
  };
  // 合格的姿势不足3个, 返回上一页
  const handleGoBackMaterial = () => {
    return (
      <div className="checkNoPostThree">
        <InfoCircleOutlined
          style={{
            color: '#9FA8B9',
            fontSize: '18px',
            marginRight: '4.5px'
          }}
        />
        <span style={{ color: '#8C8C8C' }}>抱歉，您上传的商品素材解析后，暂不满足生成模特图要求</span>
        <div className="textBnt">
          <span style={{ color: '#FF4D4A' }}>请上传至少三个包含完整真人模特的素材</span>
          <div onClick={goMaterial}>上传素材</div>
        </div>
      </div>
    );
  };
  // 是否显示弹窗
  const [showOverlay, setShowOverlay] = useState(false);
  // 当前弹窗内容
  const [currentPreFile, setCurrentPreFile] = useState<any>({});
  // 预览图片
  const preView = (item: any) => {
    setCurrentPreFile(item);
    setShowOverlay(true);
  };
  // 切换弹窗内容
  // 左右箭头点击事件
  const onLeftOrRightArrowClick = (type:string) => {
    const arr = (!isEmpty(poseList) ? [...poseList] : []).filter((i) => !isEmpty(i)).map((i: any) => i);
    const index = arr.findIndex((i: any) => i.id === currentPreFile.id);
    if (type === 'left') {
      if (index > 0) {
        setCurrentPreFile(arr[index - 1]);
      }
    } else if (type === 'right') {
      if (index < arr.length - 1) {
        setCurrentPreFile(arr[index + 1]);
      }
    }
  };
  return (
    <>
      <div className="post-slider">
        {
          !isEmpty(poseList) && !(poseList.length < 6) && (
          <div className="prev-button" onClick={goToPrevious}>
            <div className="prev-button-return">
              <img
                src={returnIcon} alt=""
                className="return-icon"
              />
            </div>
          </div>
          )
      }
        <div className="image-container">
          {
           isEmpty(poseList)
           && (
           <div className="loading-main">
             {progress.map((value, index) => (
               // eslint-disable-next-line react/no-array-index-key
               <div className="loading-item" key={index}>
                 <Progress
                   type="circle" size={30}
                   percent={value}
                 />
                 <div className="loading-item-title">正在读取中</div>
               </div>
             ))}
           </div>
           )
        }
          {!isEmpty(poseList) && poseList.slice(startIndex, startIndex + 6).map((image: any, index:number) => (
          // eslint-disable-next-line react/no-array-index-key
            <div className="image-container-item" key={index}>
              <img
                src={resizeOSSImage(image.clothAndBodyMask, 100, 134)} alt=""
                key={image.id}
                onClick={() => preView(image)}
                className="lazyL-load-image-sing"
              />

              {
                  clickIndex.includes(image.assetTaskId) ? (
                    <img
                      src={modelSelect} alt=""
                      className="select-icon"
                      onClick={() => handleClickImg(image)}
                    />
                  ) : (
                    <div className="no-select" onClick={() => handleClickImg(image)}>
                      <div className="no-select-icon" />
                    </div>
                  )
              }

              <div className="lazyL-load-image-meng" onClick={() => preView(image)} />
            </div>
          ))}
        </div>
        {
          !isEmpty(poseList) && !(poseList.length < 6) && (
          <div className="next-button" onClick={goToNext}>
            <div className="next-button-next">
              <img
                src={nextIcon}
                alt=""
                className="next-icon"
              />
            </div>
          </div>
          )
      }
        {!isEmpty(poseList) && false && poseList.length < 1 && handleGoBackMaterial()}
      </div>
      {showOverlay && !isEmpty(currentPreFile) && (
        <Overlay
          onClose={() => setShowOverlay(false)}
          onLeftArrowClick={() => onLeftOrRightArrowClick('left')}
          onRightArrowClick={() => onLeftOrRightArrowClick('right')}
        >
          <>
            {poseList.map((item:any) => {
              if (item.id === currentPreFile.id) {
                return (
                  <div className="prePoseViewModal" key={item?.id}>
                    <img
                      className="preViewModal-img"
                      src={currentPreFile.clothAndBodyMask}
                      alt=""
                    />
                  </div>
                );
              }
              return null;
            })}
          </>
        </Overlay>
      )}
    </>
  );
}

export default PosePlay;
