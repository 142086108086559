import { createGlobalState } from 'react-hooks-global-state';
import { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { getAllGenerateList } from '@/api/modelInfo.ts';

const initialState = {
  /** 姿势数组 */
  generateHistoryList: [],
  isHistoryLoading: false,
  taskHistoryMainId: '',
};
const { useGlobalState } = createGlobalState(initialState);

const shuffleArray = (array: any) => {
  const shuffledArray = [...array];
  let currentIndex = shuffledArray.length;

  while (currentIndex > 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex);
    // eslint-disable-next-line no-plusplus
    currentIndex--;
    [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
      shuffledArray[randomIndex],
      shuffledArray[currentIndex]
    ];
  }

  return shuffledArray;
};

let isHistoryPollingRef = true;

/**
 * @description 模特编辑参数
 */
export const useGenerateHistoryList = (taskMainId: string) => {
  const [generateHistoryList, setGenerateHistoryList] = useGlobalState('generateHistoryList');
  const [isHistoryLoading, setIsHistoryLoading] = useGlobalState('isHistoryLoading');
  const [taskHistoryMainId, setTaskHistoryMainId] = useGlobalState('taskHistoryMainId');
  const openBtn = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsHistoryLoading(true);
        const generateImgResult = await getAllGenerateList({
          mainId: taskMainId
        });
        if (!isEmpty(generateImgResult)) {
          setTimeout(() => {
            setGenerateHistoryList((prevHistoryList: never[]) => {
              // 创建一个新的状态对象
              const newHistoryList = [...prevHistoryList];
              Object.keys(generateImgResult).forEach((key) => {
                const generateImgResultItem = generateImgResult[key];
                // 查询generateHistoryList是否存在该key
                if (!isEmpty(newHistoryList[key])) {
                  // 如果存在，则先将历史数组中的数据解析出来
                  if (!newHistoryList[key].generateStatus) {
                    Object.keys(newHistoryList[key].data).forEach((genKey) => {
                      const genHistoryItem = newHistoryList[key].data[genKey];
                      const genNewItemData = generateImgResultItem.data[genKey];
                      genHistoryItem?.forEach((item) => {
                        if (!isEmpty(item)) {
                          // 如果还没有结果图
                          const updateItem = genNewItemData.find((newItem) => newItem?.id === item?.id);
                          // eslint-disable-next-line no-param-reassign
                          item.generateTaskProgress = updateItem?.generateTaskProgress || 0;
                          // eslint-disable-next-line no-param-reassign
                          item.resultUrl = updateItem?.resultUrl;
                          // eslint-disable-next-line no-param-reassign
                          item.originalImageLink = updateItem?.originalImageLink;
                          // eslint-disable-next-line no-param-reassign
                          item.status = updateItem?.status;
                        }
                      });
                    });
                    newHistoryList[key].generateStatus = generateImgResultItem.generateStatus;
                  }
                } else {
                  Object.keys(generateImgResultItem.data).forEach((newKey) => {
                    generateImgResultItem.data[newKey] = shuffleArray(generateImgResultItem.data[newKey]);
                  });
                  // 如果不存在，则添加
                  newHistoryList[key] = generateImgResultItem;
                }
              });
              return newHistoryList;
            });
            const genLength = Object.keys(generateImgResult).filter((resultKey) => !generateImgResult[resultKey].generateStatus);
            if (isEmpty(genLength)) {
              isHistoryPollingRef = false;
            } else {
              isHistoryPollingRef = true;
            }
          }, 0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsHistoryLoading(false);
      }
    };
    if (openBtn.current || taskMainId !== taskHistoryMainId) {
      if (taskMainId !== taskHistoryMainId) {
        setTaskHistoryMainId(taskMainId);
        setGenerateHistoryList([]);
      }
      fetchData();
      openBtn.current = false;
    }
    const interval = setInterval(() => {
      if (isHistoryPollingRef || taskMainId !== taskHistoryMainId) {
        if (taskMainId !== taskHistoryMainId) {
          setTaskHistoryMainId(taskMainId);
          setGenerateHistoryList([]);
        }
        fetchData();
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [generateHistoryList, taskMainId, setGenerateHistoryList, setIsHistoryLoading, taskHistoryMainId]);

  return {
    generateHistoryList,
    isHistoryLoading,
    taskHistoryMainId,
    setGenerateHistoryList,
  };
};
